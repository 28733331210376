import React from "react";
import doctor from "../../Assets/Researhes/doctor.jpg";
import MoreResearchesCard from "./MoreResearchesCard";

const MoreResearches = () => {
  return (
    <div className="lg:w-10/12 w-11/12 mx-auto grid md:grid-cols-12 grid-cols-1 gap-5 my-10">
      {/* first div */}
      <div className="lg:col-span-3">
        <img className="object-cover rounded-lg shadow" src={doctor} alt="" />
        <div>
          <h2 className="text-lg font-semibold my-5">CareSee</h2>
          <p className="text-start">
            CareSee is a comprehensive software for Binocular Vision Assessment
            & vision Therapy for Amblyopia, Asthenopia, etc. CareSee offers wide
            range of clinically proven and research based Therapy exercises.
          </p>
        </div>
        <div>
          <h1 className="text-lg font-semibold my-5">CATEGORIES</h1>
          <p>Amblyopia</p>
          <p>Vision Therapy</p>
          <p>Binocular Vision</p>
          <p>CareSee Exercise</p>
          <p>Lectures</p>
          <p>FAQ</p>
        </div>
      </div>

      {/* second div */}
      <div className="md:col-span-9">
        <MoreResearchesCard />
      </div>
    </div>
  );
};

export default MoreResearches;
