import React from "react";

const DoctorTransactionsTable = ({ order }) => {
  console.log(order);
  return (
    <tr>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.package?.userName}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.package?.packageName}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        <button className="bg-green-400 text-white py-1 px-4 rounded-full">
          {order?.amount}{" "}
        </button>
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.package?.userEmail}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.package?.issueDate}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        <button className="bg-green-400 text-white py-1 px-4 rounded-full">
          {order?.transactionId}
        </button>
      </td>
    </tr>
  );
};

export default DoctorTransactionsTable;
