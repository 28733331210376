import { Menu, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";

const DoctorDashboardHeader = () => {
  const { logout } = AuthUser();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="">
          <Icon
            className="text-4xl cursor-pointer hover:text-primary duration-500"
            icon="ei:user"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="editProfile"
                  className={`${
                    active
                      ? "bg-violet-500 text-white flex gap-2"
                      : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}
                >
                  <Icon className="text-lg" icon="tabler:user-edit" />{" "}
                  <span>Edit Profile</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="changePassword"
                  className={`${
                    active
                      ? "bg-violet-500 text-white flex gap-2"
                      : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}
                >
                  <Icon
                    className="text-lg"
                    icon="ic:round-published-with-changes"
                  />{" "}
                  <span>Change Password</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={logout}
                  className={`${
                    active
                      ? "bg-violet-500 text-white flex gap-2"
                      : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}
                >
                  <Icon className="text-lg" icon="basil:logout-solid" />{" "}
                  <span>LogOut</span>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DoctorDashboardHeader;
