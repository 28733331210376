import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import ClinicalFindingTable from "./ClinicalFindingTable";
import { toast } from "react-toastify";
import moment from "moment";

const ClinicalFinding = ({ patientId }) => {
  // console.log(patientId);

  //load patient data
  // State to store patient data
  const [patientData, setPatientData] = useState(null);

  // Function to fetch patient data
  const fetchPatientData = async () => {
    try {
      const response = await axios.get(
        `https://servercareseebd.careseebd.com/api/v1/user/${patientId}`
      );
      setPatientData(response.data); // Assuming the API response contains patient data
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  useEffect(() => {
    // Fetch patient data when the component mounts
    fetchPatientData();
  }, [patientId]);

  const [leftSph, setLeftSph] = useState("");
  const [leftCyl, setLeftCyl] = useState("");
  const [leftAxis, setLeftAxis] = useState("");
  const [leftAdd, setLeftAdd] = useState("");
  const [leftDistance, setLeftDistance] = useState("");
  const [leftLogMar1, setLeftLogMar1] = useState("");
  const [leftNear, setLeftNear] = useState("");
  const [leftLogMar2, setLeftLogMar2] = useState("");
  const [leftClinicalDiagnosis, setLeftClinicalDiagnosis] = useState("");

  const [rightSph, setRightSph] = useState("");
  const [rightCyl, setRightCyl] = useState("");
  const [rightAxis, setRightAxis] = useState("");
  const [rightAdd, setRightAdd] = useState("");
  const [rightDistance, setRightDistance] = useState("");
  const [rightLogMar1, setRightLogMar1] = useState("");
  const [rightNear, setRightNear] = useState("");
  const [rightLogMar2, setRightLogMar2] = useState("");
  const [rightClinicalDiagnosis, setRightClinicalDiagnosis] = useState("");

  const [selected, setSelected] = useState("");
  let defaultDate = moment().format("YYYY-MM-D");

  const [date, setDate] = useState(defaultDate);

  const options = [
    { label: "Glasses", color: "text-red-400" },
    { label: "Contact Lens", color: "text-green-400" },
    { label: "Unaided", color: "text-blue-400" },
  ];

  const getColor = (optionLabel) => {
    const option = options.find((op) => op.label === optionLabel);
    return option ? option.color : "text-gray-400";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      userId: patientData?.data?._id,
      userName: patientData?.data?.name,
      userEmail: patientData?.data?.email,
      date: date,
      eyeType: selected,
      leftEye: {
        sph: leftSph,
        cyl: leftCyl,
        axis: leftAxis,
        add: leftAdd,
        distance: leftDistance,
        logMar1: leftLogMar1,
        near: leftNear,
        logMar2: leftLogMar2,
        clinicalDiagnosis: leftClinicalDiagnosis,
      },
      rightEye: {
        sph: rightSph,
        cyl: rightCyl,
        axis: rightAxis,
        add: rightAdd,
        distance: rightDistance,
        logMar1: rightLogMar1,
        near: rightNear,
        logMar2: rightLogMar2,
        clinicalDiagnosis: rightClinicalDiagnosis,
      },
    };

    try {
      const response = await fetch(
        "https://servercareseebd.careseebd.com/api/v1/findings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Data submitted successfully:", data);
        toast.success("Data submitted successfully!");

        // Reset form fields to their initial values
        setLeftSph("");
        setLeftCyl("");
        setLeftAxis("");
        setLeftAdd("");
        setLeftDistance("");
        setLeftLogMar1("");
        setLeftNear("");
        setLeftLogMar2("");
        setLeftClinicalDiagnosis("");

        setRightSph("");
        setRightCyl("");
        setRightAxis("");
        setRightAdd("");
        setRightDistance("");
        setRightLogMar1("");
        setRightNear("");
        setRightLogMar2("");
        setRightClinicalDiagnosis("");

        setSelected("");
        setDate(date); // Reset date to its default value
      } else {
        console.error("Error submitting the data:", data);
        toast.error("Error submitting the data.");
      }
    } catch (error) {
      console.error("There was an error posting the data:", error);
      toast.error("There was an error posting the data. Please try again.");
    }
  };

  return (
    <div className="mt-3 ">
      <div>
        {/* form */}

        <div className="  rounded-md ">
          <div className="text-xl font-semibold">
            <h1>Clinical Findings</h1>
          </div>
          <div className="flex flex-wrap  items-center space-x-4 justify-between bg-gray-50 p-3 rounded-md mt-3">
            <div className=" flex items-start  justify-center">
              {options.map((option) => (
                <label
                  key={option.label}
                  className={`flex items-center cursor-pointer px-3 py-2`}
                >
                  <input
                    type="radio"
                    name="vision"
                    value={option.label}
                    onChange={(e) => setSelected(e.target.value)}
                    className={`mr-2 cursor-pointer ${getColor(option.label)}`}
                  />
                  {option.label}
                </label>
              ))}
            </div>
            <div className="">
              <div className=" mb-2 font-medium">
                <label for="dropdown">Date</label>
              </div>
              <div>
                <input
                  type="date"
                  onChange={(e) => setDate(e?.target?.value)}
                  value={date}
                  className="py-1 px-3 border rounded-md"
                />
              </div>
            </div>
          </div>
          <form className="bg-gray-50 py-2 mt-5" onSubmit={handleSubmit}>
            <div className=" grid lg:grid-cols-2 grid-cols-1">
              <div className=" m-3">
                <div className=" ">
                  <div className=" text-center font-semibold text-xl w-fit  border-b-secondary border-b-2 mx-auto pb-1 mb-2">
                    <h1 className="">LEFT EYE</h1>
                  </div>
                  <div className="bg-white p-3 rounded-md">
                    <div className=" mb-2">
                      <label for="dropdown">Refraction</label>
                    </div>
                    <div className=" grid grid-cols-4  ">
                      <div>
                        <select
                          name="sph"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={leftSph}
                          onChange={(e) => setLeftSph(e.target.value)}
                        >
                          <option value="">SPH </option>
                          <option value="-25.00">-25.00</option>
                          <option value="-24.75">-24.75</option>
                          <option value="-24.50">-24.50</option>
                          <option value="-24.25">-24.25</option>

                          <option value="-24.00">-24.00</option>
                          <option value="-23.75">-23.75</option>
                          <option value="-23.50">-23.50</option>
                          <option value="-23.25">-23.25</option>

                          <option value="-23.00">-23.00</option>
                          <option value="-22.75">-22.75</option>
                          <option value="-22.50">-22.50</option>
                          <option value="-22.25">-22.25</option>

                          <option value="-22.00">-22.00</option>
                          <option value="-21.75">-21.75</option>
                          <option value="-21.50">-21.50</option>
                          <option value="-21.25">-21.25</option>

                          <option value="-21.00">-21.00</option>
                          <option value="-20.75">-20.75</option>
                          <option value="-20.50">-20.50</option>
                          <option value="-20.25">-20.25</option>

                          <option value="-20.00">-20.00</option>
                          <option value="-19.75">-19.75</option>
                          <option value="-19.50">-19.50</option>
                          <option value="-19.25">-19.25</option>
                          <option value="-19.00">-19.00</option>

                          <option value="-18.75">-18.75</option>
                          <option value="-18.50">-18.50</option>
                          <option value="-18.25">-18.25</option>
                          <option value="-18.00">-18.00</option>

                          <option value="-17.75">-17.75</option>
                          <option value="-17.50">-17.50</option>
                          <option value="-17.25">-17.25</option>
                          <option value="-17.00">-17.00</option>

                          <option value="-16.75">-16.75</option>
                          <option value="-16.50">-16.50</option>
                          <option value="-16.25">-16.25</option>
                          <option value="-16.00">-16.00</option>

                          <option value="-15.75">-15.75</option>
                          <option value="-15.50">-15.50</option>
                          <option value="-15.25">-15.25</option>
                          <option value="-15.00">-15.00</option>

                          <option value="-14.75">-14.75</option>
                          <option value="-14.50">-14.50</option>
                          <option value="-14.25">-14.25</option>
                          <option value="-14.00">-14.00</option>

                          <option value="-13.75">-13.75</option>
                          <option value="-13.50">-13.50</option>
                          <option value="-13.25">-13.25</option>
                          <option value="-13.00">-13.00</option>

                          <option value="-12.75">-12.75</option>
                          <option value="-12.50">-12.50</option>
                          <option value="-12.25">-12.25</option>
                          <option value="-12.00">-12.00</option>

                          <option value="-11.75">-11.75</option>
                          <option value="-11.50">-11.50</option>
                          <option value="-11.25">-11.25</option>
                          <option value="-11.00">-11.00</option>

                          <option value="-10.75">-10.75</option>
                          <option value="-10.50">-10.50</option>
                          <option value="-10.25">-10.25</option>
                          <option value="-10.00">-10.00</option>

                          <option value="-9.75">-9.75</option>
                          <option value="-9.50">-9.50</option>
                          <option value="-9.25">-9.25</option>
                          <option value="-9.00">-9.00</option>

                          <option value="-8.75">-8.75</option>
                          <option value="-8.50">-8.50</option>
                          <option value="-8.25">-8.25</option>
                          <option value="-8.00">-8.00</option>

                          <option value="-7.75">-7.75</option>
                          <option value="-7.50">-7.50</option>
                          <option value="-7.25">-7.25</option>
                          <option value="-7.00">-7.00</option>

                          <option value="-6.75">-6.75</option>
                          <option value="-6.50">-6.50</option>
                          <option value="-6.25">-6.25</option>
                          <option value="-6.00">-6.00</option>

                          <option value="-5.75">-5.75</option>
                          <option value="-5.50">-5.50</option>
                          <option value="-5.25">-5.25</option>
                          <option value="-5.00">-5.00</option>

                          <option value="-4.75">-4.75</option>
                          <option value="-4.50">-4.50</option>
                          <option value="-4.25">-4.25</option>
                          <option value="-4.00">-4.00</option>

                          <option value="-3.75">-3.75</option>
                          <option value="-3.50">-3.50</option>
                          <option value="-3.25">-3.25</option>
                          <option value="-3.00">-3.00</option>

                          <option value="-2.75">-2.75</option>
                          <option value="-2.50">-2.50</option>
                          <option value="-2.25">-2.25</option>
                          <option value="-2.00">-2.00</option>

                          <option value="-1.75">-1.75</option>
                          <option value="-1.50">-1.50</option>
                          <option value="-1.25">-1.25</option>
                          <option value="-1.00">-1.00</option>

                          <option value="-0.75">-0.75</option>
                          <option value="-0.50">-0.50</option>
                          <option value="-0.25">-0.25</option>
                          <option value="-0.00">-0.00</option>

                          <option value="+0.25">+0.25</option>
                          <option value="+0.50">+0.50</option>
                          <option value="+0.75">+0.75</option>
                          <option value="+1.00">+1.00</option>
                          <option value="+1.25">+1.25</option>
                          <option value="+1.50">+1.50</option>
                          <option value="+1.75">+1.75</option>

                          <option value="+2.00">+2.00</option>
                          <option value="+2.25">+2.25</option>
                          <option value="+2.50">+2.50</option>
                          <option value="+2.75">+2.75</option>

                          <option value="+3.00">+3.00</option>
                          <option value="+3.25">+3.25</option>
                          <option value="+3.50">+3.50</option>
                          <option value="+3.75">+3.75</option>

                          <option value="+4.00">+4.00</option>
                          <option value="+4.25">+4.25</option>
                          <option value="+4.50">+4.50</option>
                          <option value="+4.75">+4.75</option>

                          <option value="+5.00">+5.00</option>
                          <option value="+5.25">+5.25</option>
                          <option value="+5.50">+5.50</option>
                          <option value="+5.75">+5.75</option>

                          <option value="+6.00">+6.00</option>
                          <option value="+6.25">+6.25</option>
                          <option value="+6.50">+6.50</option>
                          <option value="+6.75">+6.75</option>

                          <option value="+7.00">+7.00</option>
                          <option value="+7.25">+7.25</option>
                          <option value="+7.50">+7.50</option>
                          <option value="+7.75">+7.75</option>

                          <option value="+8.00">+8.00</option>
                          <option value="+8.25">+8.25</option>
                          <option value="+8.50">+8.50</option>
                          <option value="+8.75">+8.75</option>

                          <option value="+9.00">+9.00</option>
                          <option value="+9.25">+9.25</option>
                          <option value="+9.50">+9.50</option>
                          <option value="+9.75">+9.75</option>

                          <option value="+10.00">+10.00</option>
                          <option value="+10.25">+10.25</option>
                          <option value="+10.50">+10.50</option>
                          <option value="+10.75">+10.75</option>

                          <option value="+11.00">+11.00</option>
                          <option value="+11.25">+11.25</option>
                          <option value="+11.50">+11.50</option>
                          <option value="+11.75">+11.75</option>

                          <option value="+12.00">+12.00</option>
                          <option value="+12.25">+12.25</option>
                          <option value="+12.50">+12.50</option>
                          <option value="+12.75">+12.75</option>

                          <option value="+13.00">+13.00</option>
                          <option value="+13.25">+13.25</option>
                          <option value="+13.50">+13.50</option>
                          <option value="+13.75">+13.75</option>

                          <option value="+14.00">+14.00</option>
                          <option value="+14.25">+14.25</option>
                          <option value="+14.50">+14.50</option>
                          <option value="+14.75">+14.75</option>

                          <option value="+15.00">+15.00</option>
                          <option value="+15.25">+15.25</option>
                          <option value="+15.50">+15.50</option>
                          <option value="+15.75">+15.75</option>

                          <option value="+16.00">+16.00</option>
                          <option value="+16.25">+16.25</option>
                          <option value="+16.50">+16.50</option>
                          <option value="+16.75">+16.75</option>

                          <option value="+17.00">+17.00</option>
                          <option value="+17.25">+17.25</option>
                          <option value="+17.50">+17.50</option>
                          <option value="+17.75">+17.75</option>

                          <option value="+18.00">+18.00</option>
                          <option value="+18.25">+18.25</option>
                          <option value="+18.50">+18.50</option>
                          <option value="+18.75">+18.75</option>

                          <option value="+19.00">+19.00</option>
                          <option value="+19.25">+19.25</option>
                          <option value="+19.50">+19.50</option>
                          <option value="+19.75">+19.75</option>

                          <option value="+21.00">+21.00</option>
                          <option value="+21.25">+21.25</option>
                          <option value="+21.50">+21.50</option>
                          <option value="+21.75">+21.75</option>

                          <option value="+22.00">+22.00</option>
                          <option value="+22.25">+22.25</option>
                          <option value="+22.50">+22.50</option>
                          <option value="+22.75">+22.75</option>

                          <option value="+23.00">+23.00</option>
                          <option value="+23.25">+23.25</option>
                          <option value="+23.50">+23.50</option>
                          <option value="+23.75">+23.75</option>

                          <option value="+24.00">+24.00</option>
                          <option value="+24.25">+24.25</option>
                          <option value="+24.75">+24.75</option>
                          <option value="+24.75">+24.75</option>

                          <option value="+25.00">+25.00</option>
                        </select>
                      </div>
                      <div>
                        <select
                          name="cyl"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={leftCyl}
                          onChange={(e) => setLeftCyl(e.target.value)}
                        >
                          <option value="choice1">CYL </option>
                          <option value="-25.00">-25.00</option>
                          <option value="-24.75">-24.75</option>
                          <option value="-24.50">-24.50</option>
                          <option value="-24.25">-24.25</option>

                          <option value="-24.00">-24.00</option>
                          <option value="-23.75">-23.75</option>
                          <option value="-23.50">-23.50</option>
                          <option value="-23.25">-23.25</option>

                          <option value="-23.00">-23.00</option>
                          <option value="-22.75">-22.75</option>
                          <option value="-22.50">-22.50</option>
                          <option value="-22.25">-22.25</option>

                          <option value="-22.00">-22.00</option>
                          <option value="-21.75">-21.75</option>
                          <option value="-21.50">-21.50</option>
                          <option value="-21.25">-21.25</option>

                          <option value="-21.00">-21.00</option>
                          <option value="-20.75">-20.75</option>
                          <option value="-20.50">-20.50</option>
                          <option value="-20.25">-20.25</option>

                          <option value="-20.00">-20.00</option>
                          <option value="-19.75">-19.75</option>
                          <option value="-19.50">-19.50</option>
                          <option value="-19.25">-19.25</option>
                          <option value="-19.00">-19.00</option>

                          <option value="-18.75">-18.75</option>
                          <option value="-18.50">-18.50</option>
                          <option value="-18.25">-18.25</option>
                          <option value="-18.00">-18.00</option>

                          <option value="-17.75">-17.75</option>
                          <option value="-17.50">-17.50</option>
                          <option value="-17.25">-17.25</option>
                          <option value="-17.00">-17.00</option>

                          <option value="-16.75">-16.75</option>
                          <option value="-16.50">-16.50</option>
                          <option value="-16.25">-16.25</option>
                          <option value="-16.00">-16.00</option>

                          <option value="-15.75">-15.75</option>
                          <option value="-15.50">-15.50</option>
                          <option value="-15.25">-15.25</option>
                          <option value="-15.00">-15.00</option>

                          <option value="-14.75">-14.75</option>
                          <option value="-14.50">-14.50</option>
                          <option value="-14.25">-14.25</option>
                          <option value="-14.00">-14.00</option>

                          <option value="-13.75">-13.75</option>
                          <option value="-13.50">-13.50</option>
                          <option value="-13.25">-13.25</option>
                          <option value="-13.00">-13.00</option>

                          <option value="-12.75">-12.75</option>
                          <option value="-12.50">-12.50</option>
                          <option value="-12.25">-12.25</option>
                          <option value="-12.00">-12.00</option>

                          <option value="-11.75">-11.75</option>
                          <option value="-11.50">-11.50</option>
                          <option value="-11.25">-11.25</option>
                          <option value="-11.00">-11.00</option>

                          <option value="-10.75">-10.75</option>
                          <option value="-10.50">-10.50</option>
                          <option value="-10.25">-10.25</option>
                          <option value="-10.00">-10.00</option>

                          <option value="-9.75">-9.75</option>
                          <option value="-9.50">-9.50</option>
                          <option value="-9.25">-9.25</option>
                          <option value="-9.00">-9.00</option>

                          <option value="-8.75">-8.75</option>
                          <option value="-8.50">-8.50</option>
                          <option value="-8.25">-8.25</option>
                          <option value="-8.00">-8.00</option>

                          <option value="-7.75">-7.75</option>
                          <option value="-7.50">-7.50</option>
                          <option value="-7.25">-7.25</option>
                          <option value="-7.00">-7.00</option>

                          <option value="-6.75">-6.75</option>
                          <option value="-6.50">-6.50</option>
                          <option value="-6.25">-6.25</option>
                          <option value="-6.00">-6.00</option>

                          <option value="-5.75">-5.75</option>
                          <option value="-5.50">-5.50</option>
                          <option value="-5.25">-5.25</option>
                          <option value="-5.00">-5.00</option>

                          <option value="-4.75">-4.75</option>
                          <option value="-4.50">-4.50</option>
                          <option value="-4.25">-4.25</option>
                          <option value="-4.00">-4.00</option>

                          <option value="-3.75">-3.75</option>
                          <option value="-3.50">-3.50</option>
                          <option value="-3.25">-3.25</option>
                          <option value="-3.00">-3.00</option>

                          <option value="-2.75">-2.75</option>
                          <option value="-2.50">-2.50</option>
                          <option value="-2.25">-2.25</option>
                          <option value="-2.00">-2.00</option>

                          <option value="-1.75">-1.75</option>
                          <option value="-1.50">-1.50</option>
                          <option value="-1.25">-1.25</option>
                          <option value="-1.00">-1.00</option>

                          <option value="-0.75">-0.75</option>
                          <option value="-0.50">-0.50</option>
                          <option value="-0.25">-0.25</option>
                          <option value="-0.00">-0.00</option>

                          <option value="+0.25">+0.25</option>
                          <option value="+0.50">+0.50</option>
                          <option value="+0.75">+0.75</option>

                          <option value="+1.00">+1.00</option>
                          <option value="+1.25">+1.25</option>
                          <option value="+1.50">+1.50</option>
                          <option value="+1.75">+1.75</option>

                          <option value="+2.00">+2.00</option>
                          <option value="+2.25">+2.25</option>
                          <option value="+2.50">+2.50</option>
                          <option value="+2.75">+2.75</option>

                          <option value="+3.00">+3.00</option>
                          <option value="+3.25">+3.25</option>
                          <option value="+3.50">+3.50</option>
                          <option value="+3.75">+3.75</option>

                          <option value="+4.00">+4.00</option>
                          <option value="+4.25">+4.25</option>
                          <option value="+4.50">+4.50</option>
                          <option value="+4.75">+4.75</option>

                          <option value="+5.00">+5.00</option>
                          <option value="+5.25">+5.25</option>
                          <option value="+5.50">+5.50</option>
                          <option value="+5.75">+5.75</option>

                          <option value="+6.00">+6.00</option>
                          <option value="+6.25">+6.25</option>
                          <option value="+6.50">+6.50</option>
                          <option value="+6.75">+6.75</option>

                          <option value="+7.00">+7.00</option>
                          <option value="+7.25">+7.25</option>
                          <option value="+7.50">+7.50</option>
                          <option value="+7.75">+7.75</option>

                          <option value="+8.00">+8.00</option>
                          <option value="+8.25">+8.25</option>
                          <option value="+8.50">+8.50</option>
                          <option value="+8.75">+8.75</option>

                          <option value="+9.00">+9.00</option>
                          <option value="+9.25">+9.25</option>
                          <option value="+9.50">+9.50</option>
                          <option value="+9.75">+9.75</option>

                          <option value="+10.00">+10.00</option>
                          <option value="+10.25">+10.25</option>
                          <option value="+10.50">+10.50</option>
                          <option value="+10.75">+10.75</option>

                          <option value="+11.00">+11.00</option>
                          <option value="+11.25">+11.25</option>
                          <option value="+11.50">+11.50</option>
                          <option value="+11.75">+11.75</option>

                          <option value="+12.00">+12.00</option>
                          <option value="+12.25">+12.25</option>
                          <option value="+12.50">+12.50</option>
                          <option value="+12.75">+12.75</option>

                          <option value="+13.00">+13.00</option>
                          <option value="+13.25">+13.25</option>
                          <option value="+13.50">+13.50</option>
                          <option value="+13.75">+13.75</option>

                          <option value="+14.00">+14.00</option>
                          <option value="+14.25">+14.25</option>
                          <option value="+14.50">+14.50</option>
                          <option value="+14.75">+14.75</option>

                          <option value="+15.00">+15.00</option>
                          <option value="+15.25">+15.25</option>
                          <option value="+15.50">+15.50</option>
                          <option value="+15.75">+15.75</option>

                          <option value="+16.00">+16.00</option>
                          <option value="+16.25">+16.25</option>
                          <option value="+16.50">+16.50</option>
                          <option value="+16.75">+16.75</option>

                          <option value="+17.00">+17.00</option>
                          <option value="+17.25">+17.25</option>
                          <option value="+17.50">+17.50</option>
                          <option value="+17.75">+17.75</option>

                          <option value="+18.00">+18.00</option>
                          <option value="+18.25">+18.25</option>
                          <option value="+18.50">+18.50</option>
                          <option value="+18.75">+18.75</option>

                          <option value="+19.00">+19.00</option>
                          <option value="+19.25">+19.25</option>
                          <option value="+19.50">+19.50</option>
                          <option value="+19.75">+19.75</option>

                          <option value="+21.00">+21.00</option>
                          <option value="+21.25">+21.25</option>
                          <option value="+21.50">+21.50</option>
                          <option value="+21.75">+21.75</option>

                          <option value="+22.00">+22.00</option>
                          <option value="+22.25">+22.25</option>
                          <option value="+22.50">+22.50</option>
                          <option value="+22.75">+22.75</option>

                          <option value="+23.00">+23.00</option>
                          <option value="+23.25">+23.25</option>
                          <option value="+23.50">+23.50</option>
                          <option value="+23.75">+23.75</option>

                          <option value="+24.00">+24.00</option>
                          <option value="+24.25">+24.25</option>
                          <option value="+24.75">+24.75</option>
                          <option value="+24.75">+24.75</option>

                          <option value="+25.00">+25.00</option>
                        </select>
                      </div>

                      <div>
                        <select
                          name="axis"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={leftAxis}
                          onChange={(e) => setLeftAxis(e.target.value)}
                        >
                          <option value="choice1">Axis </option>
                          <option value="0">0</option>
                          <option value="5">5</option>
                          <option value="5">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="30">30</option>
                          <option value="35">35</option>
                          <option value="40">40</option>
                          <option value="45">45</option>
                          <option value="50">50</option>
                          <option value="55">55</option>
                          <option value="60">60</option>
                          <option value="65">65</option>
                          <option value="70">70</option>
                          <option value="80">80</option>
                          <option value="85">85</option>
                          <option value="90">90</option>
                          <option value="95">95</option>
                          <option value="100">100</option>
                          <option value="105">105</option>
                          <option value="110">110</option>
                          <option value="115">115</option>
                          <option value="220">220</option>
                          <option value="125">125</option>
                          <option value="130">130</option>
                          <option value="135">135</option>
                          <option value="140">140</option>
                          <option value="145">145</option>
                          <option value="150">150</option>
                          <option value="155">155</option>
                          <option value="160">160</option>
                          <option value="165">165</option>
                          <option value="170">170</option>
                          <option value="175">175</option>
                          <option value="180">180</option>
                        </select>
                      </div>
                      <div>
                        <select
                          name="add"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={leftAdd}
                          onChange={(e) => setLeftAdd(e.target.value)}
                        >
                          <option value="">Add </option>
                          <option value="+0.75">+0.75</option>
                          <option value="+1.00">+1.00</option>
                          <option value="+1.25">+1.25</option>
                          <option value="+1.50">+1.50</option>
                          <option value="+1.75">+1.75</option>
                          <option value="+1.75">+1.75</option>
                          <option value="+2.00">+2.00</option>
                          <option value="+2.25">+2.25</option>
                          <option value="+2.50">+2.50</option>
                          <option value="+2.75">+2.75</option>
                          <option value="+3.00">+3.00</option>
                          <option value="+3.25">+3.25</option>
                          <option value="+3.50">+3.50</option>
                          <option value="+3.75">+3.75</option>
                          <option value="+4.00">+4.00</option>
                          <option value="+4.25">+4.25</option>
                          <option value="+4.50">+4.50</option>
                          <option value="+4.75">+4.75</option>
                          <option value="+5.00">+5.00</option>
                        </select>
                      </div>
                    </div>

                    <div className=" ">
                      <div className=" mb-2 mt-2">
                        <label for="dropdown">Visual Acuity</label>
                      </div>
                      <div className=" grid grid-cols-4 ">
                        <div>
                          <select
                            name="distance"
                            id="dropdown"
                            className=" p-2 rounded-md w-24 bg-white border"
                            value={leftDistance}
                            onChange={(e) => setLeftDistance(e.target.value)}
                          >
                            <option value=" ">Distance </option>
                            <option value="PL-">PL-</option>
                            <option value="PL+">PL+</option>
                            <option value="1/60">1/60</option>
                            <option value="2/60">2/60</option>
                            <option value="3/60">3/60</option>
                            <option value="4/60">4/60</option>
                            <option value="5/60">5/60</option>
                            <option value="6/60">6/60</option>
                            <option value="6/36">6/36</option>
                            <option value="6/24">6/24</option>
                            <option value="6/18">6/18</option>
                            <option value="6/12">6/12</option>
                            <option value="6/9">6/9</option>
                            <option value="6/7.5">6/7.5</option>
                            <option value="6/6">6/6</option>
                          </select>
                        </div>
                        <div>
                          <input
                            type="text"
                            name="logMar1"
                            value={leftLogMar1}
                            onChange={(e) => setLeftLogMar1(e.target.value)}
                            placeholder="LOG MAR."
                            className="p-2 rounded-md  w-24 bg-white border "
                          />
                        </div>
                        <div>
                          <select
                            name="near"
                            id="dropdown"
                            className=" p-2 rounded-md w-24 bg-white border"
                            value={leftNear}
                            onChange={(e) => setLeftNear(e.target.value)}
                          >
                            <option value="choice1">Near </option>
                            <option value="N60">N60</option>
                            <option value="N36">N36</option>
                            <option value="N24">N24</option>
                            <option value="N18">N18</option>
                            <option value="N12">N12</option>
                            <option value="N8">N8</option>
                            <option value="N6">N6</option>
                          </select>
                        </div>
                        <div>
                          <input
                            type="text"
                            name="logMar2"
                            value={leftLogMar2}
                            onChange={(e) => setLeftLogMar2(e.target.value)}
                            placeholder="LOG MAR."
                            className="p-2 rounded-md  w-24 bg-white border"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        Clinical Diagnosis
                      </label>
                      <textarea
                        value={leftClinicalDiagnosis}
                        onChange={(e) =>
                          setLeftClinicalDiagnosis(e.target.value)
                        }
                        name="clinicalDiagnosis"
                        className="mt-1 p-2 w-full border rounded-md"
                        placeholder="Left Eye Clinical Diagnosis..."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" m-3">
                <div className=" text-center font-semibold text-xl w-fit  border-b-secondary border-b-2 mx-auto pb-1 mb-2">
                  <h1>RIGHT EYE</h1>
                </div>
                <div className="bg-white p-3 rounded-md">
                  <div className=" mb-2 ">
                    <label className=" " for="dropdown">
                      Refraction
                    </label>
                  </div>
                  <div className=" ">
                    <div className=" grid grid-cols-4 ">
                      <div>
                        <select
                          name="sph"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={rightSph}
                          onChange={(e) => setRightSph(e.target.value)}
                        >
                          <option value="">SPH </option>
                          <option value="-25.00">-25.00</option>
                          <option value="-24.75">-24.75</option>
                          <option value="-24.50">-24.50</option>
                          <option value="-24.25">-24.25</option>

                          <option value="-24.00">-24.00</option>
                          <option value="-23.75">-23.75</option>
                          <option value="-23.50">-23.50</option>
                          <option value="-23.25">-23.25</option>

                          <option value="-23.00">-23.00</option>
                          <option value="-22.75">-22.75</option>
                          <option value="-22.50">-22.50</option>
                          <option value="-22.25">-22.25</option>

                          <option value="-22.00">-22.00</option>
                          <option value="-21.75">-21.75</option>
                          <option value="-21.50">-21.50</option>
                          <option value="-21.25">-21.25</option>

                          <option value="-21.00">-21.00</option>
                          <option value="-20.75">-20.75</option>
                          <option value="-20.50">-20.50</option>
                          <option value="-20.25">-20.25</option>

                          <option value="-20.00">-20.00</option>
                          <option value="-19.75">-19.75</option>
                          <option value="-19.50">-19.50</option>
                          <option value="-19.25">-19.25</option>
                          <option value="-19.00">-19.00</option>

                          <option value="-18.75">-18.75</option>
                          <option value="-18.50">-18.50</option>
                          <option value="-18.25">-18.25</option>
                          <option value="-18.00">-18.00</option>

                          <option value="-17.75">-17.75</option>
                          <option value="-17.50">-17.50</option>
                          <option value="-17.25">-17.25</option>
                          <option value="-17.00">-17.00</option>

                          <option value="-16.75">-16.75</option>
                          <option value="-16.50">-16.50</option>
                          <option value="-16.25">-16.25</option>
                          <option value="-16.00">-16.00</option>

                          <option value="-15.75">-15.75</option>
                          <option value="-15.50">-15.50</option>
                          <option value="-15.25">-15.25</option>
                          <option value="-15.00">-15.00</option>

                          <option value="-14.75">-14.75</option>
                          <option value="-14.50">-14.50</option>
                          <option value="-14.25">-14.25</option>
                          <option value="-14.00">-14.00</option>

                          <option value="-13.75">-13.75</option>
                          <option value="-13.50">-13.50</option>
                          <option value="-13.25">-13.25</option>
                          <option value="-13.00">-13.00</option>

                          <option value="-12.75">-12.75</option>
                          <option value="-12.50">-12.50</option>
                          <option value="-12.25">-12.25</option>
                          <option value="-12.00">-12.00</option>

                          <option value="-11.75">-11.75</option>
                          <option value="-11.50">-11.50</option>
                          <option value="-11.25">-11.25</option>
                          <option value="-11.00">-11.00</option>

                          <option value="-10.75">-10.75</option>
                          <option value="-10.50">-10.50</option>
                          <option value="-10.25">-10.25</option>
                          <option value="-10.00">-10.00</option>

                          <option value="-9.75">-9.75</option>
                          <option value="-9.50">-9.50</option>
                          <option value="-9.25">-9.25</option>
                          <option value="-9.00">-9.00</option>

                          <option value="-8.75">-8.75</option>
                          <option value="-8.50">-8.50</option>
                          <option value="-8.25">-8.25</option>
                          <option value="-8.00">-8.00</option>

                          <option value="-7.75">-7.75</option>
                          <option value="-7.50">-7.50</option>
                          <option value="-7.25">-7.25</option>
                          <option value="-7.00">-7.00</option>

                          <option value="-6.75">-6.75</option>
                          <option value="-6.50">-6.50</option>
                          <option value="-6.25">-6.25</option>
                          <option value="-6.00">-6.00</option>

                          <option value="-5.75">-5.75</option>
                          <option value="-5.50">-5.50</option>
                          <option value="-5.25">-5.25</option>
                          <option value="-5.00">-5.00</option>

                          <option value="-4.75">-4.75</option>
                          <option value="-4.50">-4.50</option>
                          <option value="-4.25">-4.25</option>
                          <option value="-4.00">-4.00</option>

                          <option value="-3.75">-3.75</option>
                          <option value="-3.50">-3.50</option>
                          <option value="-3.25">-3.25</option>
                          <option value="-3.00">-3.00</option>

                          <option value="-2.75">-2.75</option>
                          <option value="-2.50">-2.50</option>
                          <option value="-2.25">-2.25</option>
                          <option value="-2.00">-2.00</option>

                          <option value="-1.75">-1.75</option>
                          <option value="-1.50">-1.50</option>
                          <option value="-1.25">-1.25</option>
                          <option value="-1.00">-1.00</option>

                          <option value="-0.75">-0.75</option>
                          <option value="-0.50">-0.50</option>
                          <option value="-0.25">-0.25</option>
                          <option value="-0.00">-0.00</option>

                          <option value="+0.25">+0.25</option>
                          <option value="+0.50">+0.50</option>
                          <option value="+0.75">+0.75</option>

                          <option value="+1.00">+1.00</option>
                          <option value="+1.25">+1.25</option>
                          <option value="+1.50">+1.50</option>
                          <option value="+1.75">+1.75</option>

                          <option value="+2.00">+2.00</option>
                          <option value="+2.25">+2.25</option>
                          <option value="+2.50">+2.50</option>
                          <option value="+2.75">+2.75</option>

                          <option value="+3.00">+3.00</option>
                          <option value="+3.25">+3.25</option>
                          <option value="+3.50">+3.50</option>
                          <option value="+3.75">+3.75</option>

                          <option value="+4.00">+4.00</option>
                          <option value="+4.25">+4.25</option>
                          <option value="+4.50">+4.50</option>
                          <option value="+4.75">+4.75</option>

                          <option value="+5.00">+5.00</option>
                          <option value="+5.25">+5.25</option>
                          <option value="+5.50">+5.50</option>
                          <option value="+5.75">+5.75</option>

                          <option value="+6.00">+6.00</option>
                          <option value="+6.25">+6.25</option>
                          <option value="+6.50">+6.50</option>
                          <option value="+6.75">+6.75</option>

                          <option value="+7.00">+7.00</option>
                          <option value="+7.25">+7.25</option>
                          <option value="+7.50">+7.50</option>
                          <option value="+7.75">+7.75</option>

                          <option value="+8.00">+8.00</option>
                          <option value="+8.25">+8.25</option>
                          <option value="+8.50">+8.50</option>
                          <option value="+8.75">+8.75</option>

                          <option value="+9.00">+9.00</option>
                          <option value="+9.25">+9.25</option>
                          <option value="+9.50">+9.50</option>
                          <option value="+9.75">+9.75</option>

                          <option value="+10.00">+10.00</option>
                          <option value="+10.25">+10.25</option>
                          <option value="+10.50">+10.50</option>
                          <option value="+10.75">+10.75</option>

                          <option value="+11.00">+11.00</option>
                          <option value="+11.25">+11.25</option>
                          <option value="+11.50">+11.50</option>
                          <option value="+11.75">+11.75</option>

                          <option value="+12.00">+12.00</option>
                          <option value="+12.25">+12.25</option>
                          <option value="+12.50">+12.50</option>
                          <option value="+12.75">+12.75</option>

                          <option value="+13.00">+13.00</option>
                          <option value="+13.25">+13.25</option>
                          <option value="+13.50">+13.50</option>
                          <option value="+13.75">+13.75</option>

                          <option value="+14.00">+14.00</option>
                          <option value="+14.25">+14.25</option>
                          <option value="+14.50">+14.50</option>
                          <option value="+14.75">+14.75</option>

                          <option value="+15.00">+15.00</option>
                          <option value="+15.25">+15.25</option>
                          <option value="+15.50">+15.50</option>
                          <option value="+15.75">+15.75</option>

                          <option value="+16.00">+16.00</option>
                          <option value="+16.25">+16.25</option>
                          <option value="+16.50">+16.50</option>
                          <option value="+16.75">+16.75</option>

                          <option value="+17.00">+17.00</option>
                          <option value="+17.25">+17.25</option>
                          <option value="+17.50">+17.50</option>
                          <option value="+17.75">+17.75</option>

                          <option value="+18.00">+18.00</option>
                          <option value="+18.25">+18.25</option>
                          <option value="+18.50">+18.50</option>
                          <option value="+18.75">+18.75</option>

                          <option value="+19.00">+19.00</option>
                          <option value="+19.25">+19.25</option>
                          <option value="+19.50">+19.50</option>
                          <option value="+19.75">+19.75</option>

                          <option value="+21.00">+21.00</option>
                          <option value="+21.25">+21.25</option>
                          <option value="+21.50">+21.50</option>
                          <option value="+21.75">+21.75</option>

                          <option value="+22.00">+22.00</option>
                          <option value="+22.25">+22.25</option>
                          <option value="+22.50">+22.50</option>
                          <option value="+22.75">+22.75</option>

                          <option value="+23.00">+23.00</option>
                          <option value="+23.25">+23.25</option>
                          <option value="+23.50">+23.50</option>
                          <option value="+23.75">+23.75</option>

                          <option value="+24.00">+24.00</option>
                          <option value="+24.25">+24.25</option>
                          <option value="+24.75">+24.75</option>
                          <option value="+24.75">+24.75</option>

                          <option value="+25.00">+25.00</option>
                        </select>
                      </div>
                      <div>
                        <select
                          name="cyl"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={rightCyl}
                          onChange={(e) => setRightCyl(e.target.value)}
                        >
                          <option value="">CYL </option>
                          <option value="-25.00">-25.00</option>
                          <option value="-24.75">-24.75</option>
                          <option value="-24.50">-24.50</option>
                          <option value="-24.25">-24.25</option>

                          <option value="-24.00">-24.00</option>
                          <option value="-23.75">-23.75</option>
                          <option value="-23.50">-23.50</option>
                          <option value="-23.25">-23.25</option>

                          <option value="-23.00">-23.00</option>
                          <option value="-22.75">-22.75</option>
                          <option value="-22.50">-22.50</option>
                          <option value="-22.25">-22.25</option>

                          <option value="-22.00">-22.00</option>
                          <option value="-21.75">-21.75</option>
                          <option value="-21.50">-21.50</option>
                          <option value="-21.25">-21.25</option>

                          <option value="-21.00">-21.00</option>
                          <option value="-20.75">-20.75</option>
                          <option value="-20.50">-20.50</option>
                          <option value="-20.25">-20.25</option>

                          <option value="-20.00">-20.00</option>
                          <option value="-19.75">-19.75</option>
                          <option value="-19.50">-19.50</option>
                          <option value="-19.25">-19.25</option>
                          <option value="-19.00">-19.00</option>

                          <option value="-18.75">-18.75</option>
                          <option value="-18.50">-18.50</option>
                          <option value="-18.25">-18.25</option>
                          <option value="-18.00">-18.00</option>

                          <option value="-17.75">-17.75</option>
                          <option value="-17.50">-17.50</option>
                          <option value="-17.25">-17.25</option>
                          <option value="-17.00">-17.00</option>

                          <option value="-16.75">-16.75</option>
                          <option value="-16.50">-16.50</option>
                          <option value="-16.25">-16.25</option>
                          <option value="-16.00">-16.00</option>

                          <option value="-15.75">-15.75</option>
                          <option value="-15.50">-15.50</option>
                          <option value="-15.25">-15.25</option>
                          <option value="-15.00">-15.00</option>

                          <option value="-14.75">-14.75</option>
                          <option value="-14.50">-14.50</option>
                          <option value="-14.25">-14.25</option>
                          <option value="-14.00">-14.00</option>

                          <option value="-13.75">-13.75</option>
                          <option value="-13.50">-13.50</option>
                          <option value="-13.25">-13.25</option>
                          <option value="-13.00">-13.00</option>

                          <option value="-12.75">-12.75</option>
                          <option value="-12.50">-12.50</option>
                          <option value="-12.25">-12.25</option>
                          <option value="-12.00">-12.00</option>

                          <option value="-11.75">-11.75</option>
                          <option value="-11.50">-11.50</option>
                          <option value="-11.25">-11.25</option>
                          <option value="-11.00">-11.00</option>

                          <option value="-10.75">-10.75</option>
                          <option value="-10.50">-10.50</option>
                          <option value="-10.25">-10.25</option>
                          <option value="-10.00">-10.00</option>

                          <option value="-9.75">-9.75</option>
                          <option value="-9.50">-9.50</option>
                          <option value="-9.25">-9.25</option>
                          <option value="-9.00">-9.00</option>

                          <option value="-8.75">-8.75</option>
                          <option value="-8.50">-8.50</option>
                          <option value="-8.25">-8.25</option>
                          <option value="-8.00">-8.00</option>

                          <option value="-7.75">-7.75</option>
                          <option value="-7.50">-7.50</option>
                          <option value="-7.25">-7.25</option>
                          <option value="-7.00">-7.00</option>

                          <option value="-6.75">-6.75</option>
                          <option value="-6.50">-6.50</option>
                          <option value="-6.25">-6.25</option>
                          <option value="-6.00">-6.00</option>

                          <option value="-5.75">-5.75</option>
                          <option value="-5.50">-5.50</option>
                          <option value="-5.25">-5.25</option>
                          <option value="-5.00">-5.00</option>

                          <option value="-4.75">-4.75</option>
                          <option value="-4.50">-4.50</option>
                          <option value="-4.25">-4.25</option>
                          <option value="-4.00">-4.00</option>

                          <option value="-3.75">-3.75</option>
                          <option value="-3.50">-3.50</option>
                          <option value="-3.25">-3.25</option>
                          <option value="-3.00">-3.00</option>

                          <option value="-2.75">-2.75</option>
                          <option value="-2.50">-2.50</option>
                          <option value="-2.25">-2.25</option>
                          <option value="-2.00">-2.00</option>

                          <option value="-1.75">-1.75</option>
                          <option value="-1.50">-1.50</option>
                          <option value="-1.25">-1.25</option>
                          <option value="-1.00">-1.00</option>

                          <option value="-0.75">-0.75</option>
                          <option value="-0.50">-0.50</option>
                          <option value="-0.25">-0.25</option>
                          <option value="-0.00">-0.00</option>

                          <option value="+0.25">+0.25</option>
                          <option value="+0.50">+0.50</option>
                          <option value="+0.75">+0.75</option>

                          <option value="+1.00">+1.00</option>
                          <option value="+1.25">+1.25</option>
                          <option value="+1.50">+1.50</option>
                          <option value="+1.75">+1.75</option>

                          <option value="+2.00">+2.00</option>
                          <option value="+2.25">+2.25</option>
                          <option value="+2.50">+2.50</option>
                          <option value="+2.75">+2.75</option>

                          <option value="+3.00">+3.00</option>
                          <option value="+3.25">+3.25</option>
                          <option value="+3.50">+3.50</option>
                          <option value="+3.75">+3.75</option>

                          <option value="+4.00">+4.00</option>
                          <option value="+4.25">+4.25</option>
                          <option value="+4.50">+4.50</option>
                          <option value="+4.75">+4.75</option>

                          <option value="+5.00">+5.00</option>
                          <option value="+5.25">+5.25</option>
                          <option value="+5.50">+5.50</option>
                          <option value="+5.75">+5.75</option>

                          <option value="+6.00">+6.00</option>
                          <option value="+6.25">+6.25</option>
                          <option value="+6.50">+6.50</option>
                          <option value="+6.75">+6.75</option>

                          <option value="+7.00">+7.00</option>
                          <option value="+7.25">+7.25</option>
                          <option value="+7.50">+7.50</option>
                          <option value="+7.75">+7.75</option>

                          <option value="+8.00">+8.00</option>
                          <option value="+8.25">+8.25</option>
                          <option value="+8.50">+8.50</option>
                          <option value="+8.75">+8.75</option>

                          <option value="+9.00">+9.00</option>
                          <option value="+9.25">+9.25</option>
                          <option value="+9.50">+9.50</option>
                          <option value="+9.75">+9.75</option>

                          <option value="+10.00">+10.00</option>
                          <option value="+10.25">+10.25</option>
                          <option value="+10.50">+10.50</option>
                          <option value="+10.75">+10.75</option>

                          <option value="+11.00">+11.00</option>
                          <option value="+11.25">+11.25</option>
                          <option value="+11.50">+11.50</option>
                          <option value="+11.75">+11.75</option>

                          <option value="+12.00">+12.00</option>
                          <option value="+12.25">+12.25</option>
                          <option value="+12.50">+12.50</option>
                          <option value="+12.75">+12.75</option>

                          <option value="+13.00">+13.00</option>
                          <option value="+13.25">+13.25</option>
                          <option value="+13.50">+13.50</option>
                          <option value="+13.75">+13.75</option>

                          <option value="+14.00">+14.00</option>
                          <option value="+14.25">+14.25</option>
                          <option value="+14.50">+14.50</option>
                          <option value="+14.75">+14.75</option>

                          <option value="+15.00">+15.00</option>
                          <option value="+15.25">+15.25</option>
                          <option value="+15.50">+15.50</option>
                          <option value="+15.75">+15.75</option>

                          <option value="+16.00">+16.00</option>
                          <option value="+16.25">+16.25</option>
                          <option value="+16.50">+16.50</option>
                          <option value="+16.75">+16.75</option>

                          <option value="+17.00">+17.00</option>
                          <option value="+17.25">+17.25</option>
                          <option value="+17.50">+17.50</option>
                          <option value="+17.75">+17.75</option>

                          <option value="+18.00">+18.00</option>
                          <option value="+18.25">+18.25</option>
                          <option value="+18.50">+18.50</option>
                          <option value="+18.75">+18.75</option>

                          <option value="+19.00">+19.00</option>
                          <option value="+19.25">+19.25</option>
                          <option value="+19.50">+19.50</option>
                          <option value="+19.75">+19.75</option>

                          <option value="+21.00">+21.00</option>
                          <option value="+21.25">+21.25</option>
                          <option value="+21.50">+21.50</option>
                          <option value="+21.75">+21.75</option>

                          <option value="+22.00">+22.00</option>
                          <option value="+22.25">+22.25</option>
                          <option value="+22.50">+22.50</option>
                          <option value="+22.75">+22.75</option>

                          <option value="+23.00">+23.00</option>
                          <option value="+23.25">+23.25</option>
                          <option value="+23.50">+23.50</option>
                          <option value="+23.75">+23.75</option>

                          <option value="+24.00">+24.00</option>
                          <option value="+24.25">+24.25</option>
                          <option value="+24.75">+24.75</option>
                          <option value="+24.75">+24.75</option>

                          <option value="+25.00">+25.00</option>
                        </select>
                      </div>
                      <div>
                        <select
                          name="axis"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={rightAxis}
                          onChange={(e) => setRightAxis(e.target.value)}
                        >
                          <option value="">Axis </option>
                          <option value="0">0</option>
                          <option value="5">5</option>
                          <option value="5">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="30">30</option>
                          <option value="35">35</option>
                          <option value="40">40</option>
                          <option value="45">45</option>
                          <option value="50">50</option>
                          <option value="55">55</option>
                          <option value="60">60</option>
                          <option value="65">65</option>
                          <option value="70">70</option>
                          <option value="80">80</option>
                          <option value="85">85</option>
                          <option value="90">90</option>
                          <option value="95">95</option>
                          <option value="100">100</option>
                          <option value="105">105</option>
                          <option value="110">110</option>
                          <option value="115">115</option>
                          <option value="220">220</option>
                          <option value="125">125</option>
                          <option value="130">130</option>
                          <option value="135">135</option>
                          <option value="140">140</option>
                          <option value="145">145</option>
                          <option value="150">150</option>
                          <option value="155">155</option>
                          <option value="160">160</option>
                          <option value="165">165</option>
                          <option value="170">170</option>
                          <option value="175">175</option>
                          <option value="180">180</option>
                        </select>
                      </div>
                      <div>
                        <select
                          name="add"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={rightAdd}
                          onChange={(e) => setRightAdd(e.target.value)}
                        >
                          <option value="choice1">Add </option>
                          <option value="+0.75">+0.75</option>
                          <option value="+1.00">+1.00</option>
                          <option value="+1.25">+1.25</option>
                          <option value="+1.50">+1.50</option>
                          <option value="+1.75">+1.75</option>
                          <option value="+1.75">+1.75</option>
                          <option value="+2.00">+2.00</option>
                          <option value="+2.25">+2.25</option>
                          <option value="+2.50">+2.50</option>
                          <option value="+2.75">+2.75</option>
                          <option value="+3.00">+3.00</option>
                          <option value="+3.25">+3.25</option>
                          <option value="+3.50">+3.50</option>
                          <option value="+3.75">+3.75</option>
                          <option value="+4.00">+4.00</option>
                          <option value="+4.25">+4.25</option>
                          <option value="+4.50">+4.50</option>
                          <option value="+4.75">+4.75</option>
                          <option value="+5.00">+5.00</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className=" ">
                    <div className=" mb-2 mt-2">
                      <label for="dropdown">Visual Acuity</label>
                    </div>
                    <div className=" grid grid-cols-4 ">
                      <div>
                        <select
                          name="distance"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={rightDistance}
                          onChange={(e) => setRightDistance(e.target.value)}
                        >
                          <option value=" ">Distance </option>
                          <option value="PL-">PL-</option>
                          <option value="PL+">PL+</option>
                          <option value="1/60">1/60</option>
                          <option value="2/60">2/60</option>
                          <option value="3/60">3/60</option>
                          <option value="4/60">4/60</option>
                          <option value="5/60">5/60</option>
                          <option value="6/60">6/60</option>
                          <option value="6/36">6/36</option>
                          <option value="6/24">6/24</option>
                          <option value="6/18">6/18</option>
                          <option value="6/12">6/12</option>
                          <option value="6/9">6/9</option>
                          <option value="6/7.5">6/7.5</option>
                          <option value="6/6">6/6</option>
                        </select>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="logMar1"
                          value={rightLogMar1}
                          onChange={(e) => setRightLogMar1(e.target.value)}
                          placeholder="LOG MAR."
                          className="p-2 rounded-md  w-24 bg-white border"
                        />
                      </div>
                      <div>
                        <select
                          name="near"
                          id="dropdown"
                          className=" p-2 rounded-md w-24 bg-white border"
                          value={rightNear}
                          onChange={(e) => setRightNear(e.target.value)}
                        >
                          <option value="">Near </option>
                          <option value="N60">N60</option>
                          <option value="N36">N36</option>
                          <option value="N24">N24</option>
                          <option value="N18">N18</option>
                          <option value="N12">N12</option>
                          <option value="N8">N8</option>
                          <option value="N6">N6</option>
                        </select>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="logMar2"
                          value={rightLogMar2}
                          onChange={(e) => setRightLogMar2(e.target.value)}
                          placeholder="LOG MAR."
                          className="p-2 rounded-md   w-24 bg-white border"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Clinical Diagnosis
                    </label>
                    <textarea
                      name="clinicalDiagnosis"
                      value={rightClinicalDiagnosis}
                      onChange={(e) =>
                        setRightClinicalDiagnosis(e.target.value)
                      }
                      className="mt-1 p-2 w-full border rounded-md"
                      placeholder="Right Eye Clinical Diagnosis..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className=" text-center mt-3 mb-5">
              <button
                type="submit"
                className="bg-secondary text-white px-3 text-lg  py-1 rounded-md hover:scale-105 duration-300"
              >
                ADD NEW RECORD !
              </button>
            </div>
          </form>
        </div>
      </div>
      <ClinicalFindingTable patientId={patientId}></ClinicalFindingTable>
    </div>
  );
};

export default ClinicalFinding;
