import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdminDoctorDetailsModal from "../AdminDashboard/AdminDashboardDoctors/AdminDoctorDetailsModal";

const SuperAdminView = () => {
  const { id } = useParams();
  const [DoctorData, setDoctorData] = useState([]);

  useEffect(() => {
    // Fetch data of admin users associated with the specified ID
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"referralId"}&&fieldValue=${id}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Admin Data:", data);
        setDoctorData(data.data); // Save the admin users data in state
      })
      .catch((error) => {
        console.error("Error fetching admin users data:", error);
      });
  }, [id]);

  // Current date formatting
  const date = new Date();
  const day = date.getDate();
  const year = date.getFullYear();
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][date.getMonth()];
  const formattedDate = `${day} ${monthName} ${year}`;

  const [selectedDoctor, setSelectedDoctor] = useState(null); // Changed from isOpen to selectedDoctor

  function closeModal() {
    setSelectedDoctor(null); // Close modal by setting selectedDoctor to null
  }

  function openModal(doctor) {
    setSelectedDoctor(doctor); // Open modal by setting selectedDoctor to the clicked doctor
  }
  return (
    <div>
      <div>
        <h2>
          A Comprehensive Guide to Reseller Doctor Lists and Their Vital Details
        </h2>
        <p className=" font-thin">{formattedDate}</p>

        <div className="w-full overflow-x-auto mt-10">
          <table
            className="w-full text-left rounded w-overflow-x-auto "
            cellspacing="0"
          >
            <tbody>
              <tr>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Phone
                </th>

                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Action
                </th>
              </tr>
              {DoctorData.map((adminUser) => (
                <tr key={adminUser._id} className="shadow">
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.name}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.email}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.phone}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.role}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.status}
                  </td>
                  <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer">
                    <Link
                      to={`/superAdminDashboard/superadmin-doctor-view-patient/${adminUser._id}`} // Replaced 'dynamic' with the doctor's ID
                      className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
                    >
                      <Icon icon="ic:outline-auto-graph" />
                    </Link>
                    <div
                      onClick={() => openModal(adminUser)}
                      className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
                    >
                      <Icon icon="basil:edit-outline" />
                    </div>
                  </td>
                  {selectedDoctor && (
                    <AdminDoctorDetailsModal
                      closeModal={closeModal}
                      isOpen={selectedDoctor !== null}
                      doctor={selectedDoctor}
                      doctorId={selectedDoctor._id}
                    />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminView;
