import { Icon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ResellerEditProfileModal from "./SuperAdminModal/ResellerEditProfileModal";

const SuperAdminAllRessellerTable = () => {
  const [reseller, setReseller] = useState([]);
  const [selectedResellerData, setSelectedResellerData] = useState(null);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"admin"}`
        );
        const data = await response.json();
        setReseller(data?.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);
  const [modalIsOpenResellerEditProfile, setModalIsOpenResellerEditProfile] =
    useState(false);

  const openModalResellerEditProfile = (reseller) => {
    setSelectedResellerData(reseller);

    setModalIsOpenResellerEditProfile(true);
  };

  const closeModalResellerEditProfile = () => {
    setModalIsOpenResellerEditProfile(false);
  };
  //cureent date
  const date = new Date();

  const day = date.getDate();
  const year = date.getFullYear();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = months[date.getMonth()];

  const formattedDate = `${day} ${monthName} ${year}`;
  return (
    <div className=" mt-3">
      <div>
        <h1 className=" text-xl">Recently Created Reseller Account</h1>
        <p className=" font-thin">{formattedDate}</p>
      </div>

      <div className="w-full overflow-x-auto mt-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto "
          cellspacing="0"
        >
          <tbody>
            <tr>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Name
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Email
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Role
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Status
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Action
              </th>
            </tr>

            {[...reseller]
              ?.reverse()
              ?.slice(0, 5)
              ?.map((resellerData) => (
                <tr key={resellerData.id} className="shadow">
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {resellerData.name}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {resellerData.email}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {resellerData.role}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {resellerData.status}
                  </td>
                  <td
                    onClick={() => openModalResellerEditProfile(resellerData)}
                    className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer"
                  >
                    <div className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300">
                      <Icon icon="basil:edit-outline" />
                    </div>
                  </td>

                  <ResellerEditProfileModal
                    resellerData={selectedResellerData}
                    isOpen={modalIsOpenResellerEditProfile}
                    closeModal={closeModalResellerEditProfile}
                  ></ResellerEditProfileModal>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuperAdminAllRessellerTable;
