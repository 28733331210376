import React from "react";

const DoctorOrdersTable = ({ order }) => {
  return (
    <tr>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.packageName}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.totalPrice}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.userEmail}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.issueDate}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
        {order?.expireDate}
      </td>
    </tr>
  );
};

export default DoctorOrdersTable;
