import { Icon } from "@iconify/react";
import React from "react";
import { useState } from "react";
import SuperAdminRessellerModal from "./SuperAdminModal/SuperAdminRessellerModal";
import SuperAdminAllRessellerTable from "./SuperAdminAllRessellerTable";
import { useEffect } from "react";

const SuperAdminDashboardIndex = () => {
  const [modalIsOpenAddResseller, setModalIsOpenAddResseller] = useState(false);
  const [reseller, setReseller] = useState([]);
  const [user, setUser] = useState({});
  const [doctor, setDoctor] = useState({});
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          "https://servercareseebd.careseebd.com/api/v1/user"
        );
        const data = await response.json();

        const resellerData = data.data.filter((user) => user.role === "admin");
        setReseller(resellerData);
        const userData = data.data.filter((user) => user.role === "user");

        setUser(userData);
        const doctorData = data.data.filter((user) => user.role === "doctor");

        setDoctor(doctorData);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  const openModalAddResseller = () => {
    setModalIsOpenAddResseller(true);
  };

  const closeModalAddResseller = () => {
    setModalIsOpenAddResseller(false);
  };

  return (
    <div className="">
      <div className=" lg:grid lg:grid-cols-3 gap-5  md:grid md:grid-cols-3 font-bold mb-4">
        <div className=" flex justify-center items-center  bg-[#E3156F] shadow text-white h-40 mb-2  max-w-xs  rounded-md gap-2 text-xl">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total Reseller: </p>
          <p>{reseller?.length}</p>
        </div>
        <div className=" flex justify-center items-center bg-[#DC3545] text-white shadow h-40 mb-2 max-w-xs rounded-md gap-2 text-xl">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total Doctor:</p>
          <p>{doctor?.length}</p>
        </div>
        <div className=" justify-center flex items-center bg-[#CF4514] text-white shadow h-40 mb-2 max-w-xs rounded-md gap-2 text-xl">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total Patient: </p>
          <p>{user?.length}</p>
        </div>
      </div>
      <div className="bg-gray-200 h-[1px] w-full "></div>

      <div className=" flex justify-start items-center gap-5 mt-5">
        <div
          onClick={openModalAddResseller}
          className="transform hover:scale-110 duration-300 flex justify-center p-3 items-center gap-2 bg-secondary shadow-md rounded-md w-36 text-white max-auto  cursor-pointer"
        >
          <Icon icon="gala:add"></Icon>
          <h1>Add reseller</h1>
        </div>

        <SuperAdminRessellerModal
          isOpen={modalIsOpenAddResseller}
          closeModal={closeModalAddResseller}
        ></SuperAdminRessellerModal>
      </div>
      <div className="bg-gray-200 h-[1px] w-full mt-5 "></div>

      <SuperAdminAllRessellerTable></SuperAdminAllRessellerTable>
    </div>
  );
};

export default SuperAdminDashboardIndex;
