import AdminDoctorPatientReport from "../Component/AdminDashboard/AdminDoctorPatient/AdminDoctorPatientReport";
import DoctorBilling from "../Component/DoctorDashboard/DoctorBilling/DoctorBilling";
import DoctorDashboardIndex from "../Component/DoctorDashboard/DoctorDashboardIndex/DoctorDashboardIndex";
import DoctorFaq from "../Component/DoctorDashboard/DoctorFaq/DoctorFaq";
import PatientEditProfile from "../Component/DoctorDashboard/PatientEditProfile/PatientEditProfile";
import ChangePassword from "../Component/UserDashboard/UserDashboardEditProfile/ChangePassword";
import EditProfile from "../Component/UserDashboard/UserDashboardEditProfile/EditProfile";
import DoctorAddNewPatient from "../Pages/DoctroDashbaord/DoctorAddNewPatient";

const DoctorRoutes = [
  { path: "overview", Component: DoctorDashboardIndex },
  { path: "billing", Component: DoctorBilling },
  { path: "faq", Component: DoctorFaq },
  { path: "changePassword", Component: ChangePassword },
  { path: "editProfile", Component: EditProfile },
  { path: "doctor-add-new-patient", Component: DoctorAddNewPatient },
  {
    path: "patient-edit-profile/:patientId",
    Component: PatientEditProfile,
  },
  {
    path: "PatientReport/:id",
    Component: AdminDoctorPatientReport,
  },
];

export default DoctorRoutes;
