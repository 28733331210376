import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import DoctorDashboardHeader from "./DoctorDashboardHeader";
import DoctorDashboardNav from "./DoctorDashboardNav";
import AuthUser from "../../Hooks/authUser";
import { useEffect } from "react";
import AuthHome from "../../Component/LoginSingup/AuthHome";

const DoctorDashboard = () => {
  const { userInfo, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo?.role === "doctor") {
    } else {
      if (userInfo) {
        logout();
      }
      navigate("/login/doctor");
    }
  }, []);
  return (
    <div>
      {/* dashboard header */}
      <div className="bg-white ">
        <div className="flex justify-between items-center py-2 lg:w-10/12 w-11/12 mx-auto min-h-[70px]">
          <Link to="/">
            <img className="w-[80%]" src={logo} alt="logo" />
          </Link>
          {/* <UserSideNav /> */}
          <div className="flex items-center gap-2">
            <div>
              <p className="text-lg font-semibold ">{userInfo?.name}</p>
              <p className="text-xs -mt-1 text-primary">Available</p>
            </div>

            <DoctorDashboardHeader />
          </div>
        </div>
      </div>
      <div className="lg:w-10/12 w-11/12 mx-auto my-8">
        <div className="">
          <DoctorDashboardNav />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default DoctorDashboard;
