import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

const Bot = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://servercareseebd.careseebd.com/api/v1/footer/getFooter`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setData(data?.data[0]);
        }
      });
  }, []);
  const whatsappNumber = data?.phone; // Updated WhatsApp number

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };

  return (
    <div>
      <div
        className="fixed bottom-5 sm:right-8 right-4 z-50 cursor-pointer text-white text-4xl bg-green-500 w-16 h-16 flex items-center justify-center rounded-full animate-bounce"
        onClick={handleWhatsAppClick}
      >
        <FaWhatsapp />
      </div>
    </div>
  );
};

export default Bot;
