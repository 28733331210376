import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const AdminPatientLicense = ({ patientId }) => {
  const [packages, setPackages] = useState([]);
  const issueDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/package/specific?fieldName=${"userId"}&&fieldValue=${patientId}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setPackages(data?.data?.[0]);
        }
      });
  }, [patientId]);
  console.log();
  return (
    <div>
      <div className="my-5 flex items-center gap-3">
        <div className="w-4 h-4 rounded-full bg-secondary shadow-primary/50 shadow-lg"></div>{" "}
        <p className="opacity-60">
          {packages?.packageName
            ? packages?.packageName
            : "Don't have any package !"}
        </p>
      </div>

      <label className="text-sm block mb-1 opacity-60">
        {packages?.expireDate ? "Expire Date" : "Today Date"}
      </label>
      <div className="w-full py-3 px-5 rounded-lg border flex items-center gap-3 opacity-60">
        <Icon icon="uiw:date" />{" "}
        <p>{packages?.expireDate ? packages?.expireDate : issueDate}</p>
      </div>
    </div>
  );
};

export default AdminPatientLicense;
