import React from "react";
import StripeCheckout from "react-stripe-checkout";
import AuthUser from "../../../Hooks/authUser";
import PostHooks from "../../../Hooks/PostHooks";
import swal from "sweetalert";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { toast } from "react-toastify";

const Payment = ({ data, packages, patient, isDisable }) => {
  const { userInfo, userRole } = AuthUser();

  const handelUpdate = async () => {
    if (packages?.expireDate) {
      await UpdateHooks(
        `https://servercareseebd.careseebd.com/api/v1/package/updatePackage/${packages?._id}`,
        data
      );
      toast?.success(`The package was Updated !`);
    } else {
      await PostHooks(
        `https://servercareseebd.careseebd.com/api/v1/package/addPackage`,
        data,
        `The package was given to ${patient?.name} !`
      );
    }
  };

  const tokenHandler = async (token) => {
    console.log("userInfo", userInfo, "userRole:", userRole);
    const pricingPaymentDetails = {
      token,
      price: data?.totalPrice,
      doctorId: userInfo?._id,
      doctorData: userInfo,
      package: data,
    };
    await fetch(
      "https://servercareseebd.careseebd.com/api/v1/transaction/addTransaction",
      {
        method: "POST",
        body: JSON.stringify(pricingPaymentDetails),
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          handelUpdate();
        } else {
          return swal("Oops", `${data.error}`, "error");
        }
      });
  };

  return (
    <StripeCheckout
      amount={data?.price * 100}
      shippingAddress
      token={tokenHandler}
      stripeKey="pk_test_51L1TVtFBIaSlFfNXVsw7wg2WrEnZ7w8b0amGGpxAiJT7sns5U0VhzfKI57g3Pdd0alwzvLSyZDeaQJPRT88ieIif00GQdQn6kg"
      currency="BDT"
    >
      <button
        type="button"
        disabled={isDisable}
        className=" bg-secondary font-semibold hover text-white px-5 py-2 hover:scale-105 duration-300 rounded-md disabled:bg-secondary/50 disabled:scale-100 disabled:cursor-not-allowed"
      >
        Payment
      </button>
    </StripeCheckout>
  );
};

export default Payment;
