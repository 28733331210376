import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ClinicalFindingTableRow from "./ClinicalFindingTableRow";
import Loading from "../../../Shared/Loading";

const ClinicalFindingTable = ({ patientId }) => {
  const [clinicalFindings, setClinicalFindings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    console.log("Patient ID:", patientId);

    axios
      .get("https://servercareseebd.careseebd.com/api/v1/findings")
      .then((response) => {
        console.log("API Data:", response.data);

        const filteredFindings = response.data.filter(
          (finding) => finding.userId === patientId
        );

        console.log("Filtered Findings:", filteredFindings);

        setClinicalFindings(filteredFindings);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching clinical findings:", error);
        setLoading(false);
      });
  }, [patientId,refetch]);

  if(loading){
return  <Loading></Loading>;
  };

  return (
    <div className="mt-10 ">
      {clinicalFindings.length === 0 ? (
        <p className="text-center text-lg text-red-500 my-5">
          No clinical findings available for this patient.
        </p>
      ) : (
        <>
          <div className="text-center text-xl font-bold ">
            <h1>USER PREVIOUS RECORD !</h1>
          </div>

          <div className="w-full overflow-x-auto mt-10">
            <table
              className="w-full text-left rounded w-overflow-x-auto "
              cellspacing="0"
            >
              <tbody className="">
                <tr className="bg-gray-50 ">
                  <th></th>
                  <th></th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    SPH <br />
                    <span className="text-gray-400 text-xs">(-25 to +25)</span>
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    CLY <br />
                    <span className="text-gray-400 text-xs">(-25 to +25)</span>
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    Axis <br />
                    <span className="text-gray-400 text-xs">(0° to 180°)</span>
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    Add <br />
                    <span className="text-gray-400 text-xs">
                      (+0.75 - +5.00)
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    Distance
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    LOG MAR
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    Near
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    LOG MAR
                  </th>
                  <th
                    scope="col"
                    className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                   Clinical Diagnosis
                  </th>
                </tr>
                {[...clinicalFindings]?.reverse()?.map((patient) => (
                  <ClinicalFindingTableRow patient={patient} refetch={refetch} setRefetch={setRefetch} /> 
                ))}
              </tbody>
            </table>
            {clinicalFindings?.length === 0 && (
              <h2 className="w-full text-xl font-semibold text-red-500 text-center my-10">
                Clinical Finding Not Found !
              </h2>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ClinicalFindingTable;
