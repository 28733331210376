import React from "react";
import Navbar from "../../../Component/WebNavbar/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../../../Component/Footer/Footer";
import Bot from "../../../Component/Bot/Bot";

const Main = () => {
  return (
    <>
      <Navbar></Navbar>
      <Outlet />
      <Bot></Bot>
      <Footer />
    </>
  );
};

export default Main;
