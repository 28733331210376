import React, { useState } from "react";

const DoctorBillOverview = () => {
  const [addAmount, setAddAmount] = useState("");
  console.log(addAmount);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-5">
      <div className="bg-white p-6 rounded-lg shadow-md my-7 flex items-center gap-5 justify-between">
        <div className="w-fit">
          <p className="text-lg font-semibold">
            <span className="text-lg">৳</span> 0.0
          </p>
          <p className="whitespace-nowrap">Wallet Balance</p>
        </div>

        <div class="relative  flex w-full items-stretch">
          <input
            type="search"
            class="relative m-0 -mr-0.5 block w-full  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3]   focus:outline-none"
            onChange={(e) => setAddAmount(e.target.value)}
            placeholder="Your add amount"
            aria-label="Search"
            aria-describedby="button-addon3"
          />

          <button
            class="relative z-[2] rounded-r border-2 border-secondary px-6 py-2 text-xs font-medium uppercase text-white transition duration-150 ease-in-out hover:bg-white bg-secondary hover:text-secondary whitespace-nowrap"
            type="button"
            id="button-addon3"
            data-te-ripple-init
          >
            Add Amount
          </button>
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md my-7 flex items-center gap-5 justify-between">
        <div className="w-fit">
          <p className="text-lg font-semibold">
            <span className="text-lg">৳</span> 0.0
          </p>
          <p className="whitespace-nowrap">Pay Now Pay Later Dues</p>
        </div>

        <button className="py-1 px-3 rounded-md bg-secondary text-white hover:bg-secondary/80 hover:scale-105 duration-300">
          Pay Now !
        </button>
      </div>
    </div>
  );
};

export default DoctorBillOverview;
