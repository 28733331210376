import React, { useState } from "react";
import AdminAllDoctorsTable from "./AdminAllDoctorsTable";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Loading from "../../../Shared/Loading";
import AuthUser from "../../../Hooks/authUser";

const AdminDashboardDoctors = () => {
  const [loading, setLoading] = useState(false);
  const { userInfo } = AuthUser();
  const [searchInput, setSearchInput] = useState("");
  const [selected, setSelected] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"doctor"}&&fieldName2=${"referralId"}&&fieldValue2=${
            userInfo?._id
          }`
        );
        const data = await response.json();
        setDoctors(data?.data);
        setFilterData(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  const filter = doctors?.filter((user) => {
    const activity = user?.isActive ? "active" : "inActive";
    return (
      (searchInput &&
        user?.name?.toLowerCase()?.includes(searchInput?.toLowerCase())) ||
      (selected && activity === selected) ||
      (selected === "all" && (activity === "active" || "inActive"))
    );
  });
  useEffect(() => {
    setFilterData(filter);
  }, [searchInput, selected]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="p-5 bg-white ">
        <div className="grid grid-cols-2 gap-10">
          <div>
            <Link
              to="/adminDashboard/overview"
              className="bg-secondary py-2 px-4 rounded-md flex items-center text-white gap-2 hover:scale-105 duration-300 shadow-lg shadow-secondary/50 cursor-pointer w-fit"
            >
              <Icon className="text-lg" icon="typcn:plus" />{" "}
              <span className="hidden md:block">Add New Doctor</span>
            </Link>
          </div>
          <div className="flex gap-5 justify-end text-sm md:text-base relative">
            <select
              onChange={(e) => setSelected(e.target.value)}
              className="border border-secondary px-3 rounded-lg"
              name="patientType"
            >
              <option value="all">All Practitioner</option>
              <option value="active">Active Practitioner</option>
              <option value="inActive">Inactive Practitioner</option>
            </select>
            <input
              onChange={(e) => setSearchInput(e.target.value)}
              className="border border-secondary rounded-lg px-3  py-2 focus:outline-none"
              type="search"
              name="search"
              placeholder="search "
            />
          </div>
        </div>

        {/* all patients */}

        <div className="w-full overflow-x-auto mt-10">
          <table
            className="w-full text-left rounded w-overflow-x-auto "
            cellspacing="0"
          >
            <tbody>
              <tr>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Action
                </th>
              </tr>
              {filterData.map((doctor) => (
                <AdminAllDoctorsTable doctor={doctor} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardDoctors;
