import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const CheckOut = () => {
  const [cartItems, setCartItems] = useState([]);
  const storedCartitem = localStorage.getItem("cartItems");
  const [orderDetails, setOrderDetails] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch order details when the component mounts
    fetchOrderDetails();
  }, []);

  const [shippingCost, setShippingCost] = useState(0);
  const [taxRate, setTaxRate] = useState(0);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(
        "https://servercareseebd.careseebd.com/api/v1/orderDetails/getOrderDetails"
      );

      if (response.data.status === "success" && response.data.data.length > 0) {
        // Extract shippingCost and taxRate from the first object in the array
        const { shippingCost, taxRate } = response.data.data[0];

        // Set variables
        setShippingCost(shippingCost);
        setTaxRate(taxRate);

        console.log("Order details:", response.data.data);
      } else {
        // Handle error or set default values if needed
        console.error("Error fetching order details:", response.data);
      }
    } catch (error) {
      // Handle network error or other issues
      console.error("API request failed:", error);
    }
  };

  console.log("Shipping Cost:", shippingCost);
  console.log("Tax Rate:", taxRate);

  // console.log(orderDetails.length > 0 ? orderDetails[0].shippingCost : null);
  useEffect(() => {
    if (storedCartitem) {
      const pasedCartItems = JSON.parse(storedCartitem);

      setCartItems(pasedCartItems);
    }
  }, []);

  console.log("crat item", cartItems);

  // Function to calculate subtotal
  const calculateSubtotal = () => {
    return cartItems.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    );
  };

  // Function to calculate shipping
  const calculateShipping = () => {
    // Use the shippingCost value fetched from the API
    return shippingCost;
  };

  // Function to calculate tax
  const calculateTax = () => {
    // Use the taxRate value fetched from the API
    return (calculateSubtotal() * taxRate) / 100;
  };

  // Function to calculate total
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const shipping = calculateShipping();
    const tax = calculateTax();
    return subtotal + shipping + tax;
  };

  const subtotal = calculateSubtotal();
  const shipping = calculateShipping();
  const tax = calculateTax();
  const total = calculateTotal();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    streetAddress: "",
    apartment: "",
    townCity: "",
    state: "",
    pinCode: "",
    phone: "",
    emailAddress: "",
    shippingMethod: "",
    orderNotes: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Send a POST request to create an order on the server
    try {
      const response = await axios.post("https://servercareseebd.careseebd.com/api/v1/orders", {
        ...formData,
        cartItems: cartItems,
        subtotal,
        shipping,
        tax,
        total,
      });

      toast.success("Order Submited ");
      localStorage.removeItem("cartItems");

      navigate(-1);

      // Handle the response as needed

      // Clear the form or show a success message
      // You can also redirect the user to a thank-you page
    } catch (error) {
      // Handle errors, show an error message, or retry the request
      toast.error("Error Creating order");
    }
  };

  console.log(cartItems);
  return (
    <div className="min-h-screen bg-gray-100">
      <form onSubmit={handleFormSubmit}>
        {/* Header */}
        <header className="bg-blue-500 p-4 text-white">
          <h1 className="text-2xl font-semibold">Billing Details</h1>
        </header>

        {/* Main content */}
        <main className=" mt-8 p-6 md:flex items-start  gap-5">
          {/* Billing Form */}
          <div className="bg-white shadow-md rounded-lg p-6   md:w-2/3">
            {/* Billing Details */}
            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2">Billing Details</h2>
              <div className="grid grid-cols-2 gap-4   ">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    First name *
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={setFormData.firstName}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Last name *
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={setFormData.lastName}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Company name (optional)
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    value={setFormData.companyName}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm  font-medium text-gray-700">
                    Country / Region *
                  </label>
                  <input
                    type="text"
                    name="country"
                    value={setFormData.country}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div className="col-span-2">
                  <label className="block text-sm font-medium  text-gray-700">
                    Street address *
                  </label>
                  <input
                    type="text"
                    name="streetAddress"
                    value={setFormData.streetAddress}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Apartment, suite, unit, etc. (optional)
                  </label>
                  <input
                    type="text"
                    name="apartment"
                    value={setFormData.apartment}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full border rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Town / City *
                  </label>
                  <input
                    type="text"
                    name="townCity"
                    value={setFormData.townCity}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 border w-full rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    State *
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={setFormData.state}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full border rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    PIN Code *
                  </label>
                  <input
                    type="text"
                    name="pinCode"
                    value={setFormData.pinCode}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full border rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Phone *
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={setFormData.phone}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full border rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email address *
                  </label>
                  <input
                    type="email"
                    name="emailAddress"
                    value={setFormData.emailAddress}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full border rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Shipping Method
              </label>
              <select
                id="shippingMethod"
                name="shippingMethod"
                value={setFormData.shippingMethod}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full py-2 px-3 border  border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="Flat Rate">Flat Rate</option>
                <option value="Express Shipping">Express Shipping</option>
              </select>
            </div>

            {/* Order Notes */}
            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2">
                Order Notes (optional)
              </h2>
              <textarea
                name="orderNotes"
                value={setFormData.orderNotes}
                onChange={handleInputChange}
                rows="4"
                className="p-2 w-full rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Add any additional notes or comments about your order."
              />
            </div>

            {/* Submit Button */}
          </div>

          <div className=" bg-white p-6 rounded-md">
            <h2 className="text-lg font-semibold">Your Order</h2>
            <table className="w-full mt-4">
              <thead>
                <tr className=" justify-between flex gap-5">
                  <th className="">Product</th>
                  <th className="">Quantity</th>
                  <th className="">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((product) => (
                  <tr key={product.name} className=" flex justify-between gap-5">
                    <td className="text-left">
                      {product.name.split(" ").slice(0, 1).join(" ")}...
                    </td>

                    <td className=""> {product.quantity}</td>

                    <td className=" ">৳{product.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="mt-4">
              <div className="flex justify-between">
                <span className="font-medium">Subtotal</span>
                <span>৳{subtotal}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Shipping</span>
                <span>৳ {shipping} </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Tax ({taxRate}%)</span>
                <span>৳ {tax}</span>
              </div>
              <hr className="my-4 border-gray-300" />

              <div className="flex justify-between mt-2">
                <span className="font-semibold">Total</span>
                <span className="text-blue-500 font-semibold"> ৳{total}</span>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold"
                >
                  Order Now
                </button>
              </div>
            </div>
          </div>
        </main>
      </form>
    </div>
  );
};

export default CheckOut;
