import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminEditDoctorPatient from "./EditDoctorPatient/AdminEditDoctorPatient";
import moment from "moment";

const AdminDoctorPatientTable = ({ patient }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);

  const issueDate = moment().format("YYYY-MM-DD");
  const [packages, setPackages] = useState([]);
  const isExpire = packages?.expireDate
    ? moment(issueDate).isAfter(packages?.expireDate)
    : true;

  function closeModal() {
    setSelectedPatient(null); // Close modal by setting selectedDoctor to null
  }

  function openModal(doctor) {
    setSelectedPatient(doctor); // Open modal by setting selectedDoctor to the clicked doctor
  }

  // load package data
  useEffect(() => {
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/package/specific?fieldName=${"userId"}&&fieldValue=${
        patient?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setPackages(data?.data?.[0]);
        }
      });
  }, []);

  return (
    <>
      <tr className="shadow">
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient.name}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient.email}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          <button className="bg-primary py-0.5 text-white text-sm px-3 rounded-full">
            {patient.phone}
          </button>
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.isActive ? ( // you should replace this with an actual condition
            <button className="bg-green-600 py-0.5 text-white text-sm px-3 rounded-full">
              Active
            </button>
          ) : (
            <button className="bg-red-500 py-0.5 text-white text-sm px-3 rounded-full">
              InActive
            </button>
          )}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {isExpire ? ( // you should replace this with an actual condition
            <button className="bg-red-500 py-0.5 text-white text-sm px-3 rounded-full">
              Expired
            </button>
          ) : (
            <button className="bg-green-600 py-0.5 text-white text-sm px-3 rounded-full">
              Active
            </button>
          )}
        </td>
        <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center">
          <Link
            to={`/adminDashboard/doctorsPatientReport/${patient._id}`}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="ic:outline-auto-graph" />
          </Link>
          <button
            onClick={() => openModal(patient)}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="basil:edit-outline" />
          </button>
        </td>
      </tr>

      {selectedPatient && (
        <AdminEditDoctorPatient
          closeModal={closeModal}
          isOpen={selectedPatient !== null}
          patient={selectedPatient}
        />
      )}
    </>
  );
};

export default AdminDoctorPatientTable;
