import React from "react";
import PingPong from "./pingpong/PingPong";

const PingPongGame = () => {
  return (
    <div>
      <PingPong></PingPong>
    </div>
  );
};

export default PingPongGame;
