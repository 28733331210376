import React from "react";

const TermsComditions = () => {
  return (
    <div className=" lg:mt-[75px] lg:ml-[175px] lg:mr-[175px] lg:mb-[75px]  m-5 ">
      <div className=" text-center font-bold text-4xl ">
        <h1 className=" underline  decoration-2">Terms & Conditions</h1>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Terms & Conditions</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically.
        </p>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Trade Name</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically.
        </p>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Usage</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Provident facere incidunt vero nemo
          dolorum laborum debitis molestiae deleniti quis, autem iste? Eos
          molestiae excepturi rerum quibusdam soluta eum reprehenderit aperiam
          ut officiis blanditiis! Cum incidunt fugit nobis facilis in architecto
          dolorum laborum rerum ipsum nulla a magni reprehenderit consequatur,
          labore voluptatem accusamus! Sit earum aspernatur explicabo debitis
          esse cupiditate vero porro quis aut illo amet nobis maxime qui
          laudantium, ea, eos voluptatibus incidunt corporis ut rem odit
          deleniti reprehenderit dolorem doloremque. Consequatur ratione
          necessitatibus accusamus, ad repudiandae esse molestiae perspiciatis
          quia. Tenetur dolor modi porro, blanditiis ullam repellat doloremque
          iste! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          recusandae voluptatum, temporibus, velit magni doloremque modi nam
          nobis eligendi praesentium aperiam adipisci accusamus deleniti tenetur
          quia id eaque est delectus consectetur expedita dicta esse. Modi et
          nostrum quasi libero culpa fuga recusandae soluta aliquid cupiditate
          delectus, laboriosam labore est mollitia atque voluptatem ipsum!
          Numquam, necessitatibus. Quisquam nemo facilis, voluptatibus, veniam
          quia libero hic reiciendis molestias culpa non nostrum eveniet iusto
          consequatur officiis quo fugit cupiditate minima alias temporibus
          voluptatum dolorem sequi. Ex labore sequi possimus, consequuntur
          itaque necessitatibus excepturi, praesentium cupiditate odit quasi
          iste voluptates quidem dolor commodi, qui eos!
        </p>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Parts of This Agreement</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically. Lorem, ipsum dolor sit
          amet consectetur adipisicing elit. Numquam a perspiciatis iusto nisi
          excepturi ipsum enim et, minus labore, ipsam, expedita veritatis
          mollitia aspernatur reprehenderit commodi laborum natus quas magnam
          id. Nulla, sed. Ratione incidunt quaerat fugit earum, animi deleniti
          accusamus ducimus porro. Quas, omnis. Voluptas unde debitis modi
          dolorum?
        </p>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Disclaimer of Warranties</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically. Lorem ipsum dolor, sit
          amet consectetur adipisicing elit. Qui maxime non, obcaecati ipsam
          hic, accusantium molestias adipisci laudantium itaque accusamus, et
          mollitia nesciunt ducimus eum ipsum! Officiis pariatur officia
          asperiores, perspiciatis laboriosam dignissimos reiciendis ratione
          voluptatibus minima repudiandae sapiente, facere maiores esse cum sit!
          Illum nam inventore nostrum qui voluptatem veniam molestias cumque
          deleniti vero debitis, cupiditate placeat amet pariatur voluptas ipsa
          expedita modi! Earum amet dolorum et nemo dignissimos unde impedit
          neque officia, nulla recusandae hic totam voluptate cumque nobis
          libero vero iusto repellendus a aut voluptas culpa? Rem accusantium
          perferendis incidunt commodi quidem voluptatibus eaque aut. Saepe
          ipsam quaerat praesentium sed explicabo? Dolores tempore quibusdam
          culpa optio dolorum nihil vitae. Assumenda soluta similique doloremque
          laboriosam fugit voluptas repudiandae quos reprehenderit facilis,
          maxime, sapiente sunt voluptatibus quia ratione? Vel explicabo fuga
          commodi laboriosam molestias maxime, eos porro. Rerum, repellat
          quibusdam aut aliquam nesciunt, distinctio, fugit suscipit harum
          provident quisquam maxime. Nisi quae vero eligendi iusto, veniam ut
          libero voluptate fuga, reiciendis aut repudiandae assumenda deleniti
          nam possimus, cum beatae dolores repellat dicta odit magnam quia.
          Officiis quia quis porro, sed, illo distinctio, provident enim quae
          vitae magnam incidunt reiciendis qui sequi? Quasi, nulla voluptas
          eligendi sapiente eum ut, maiores explicabo soluta tempore, dicta quia
          deserunt perspiciatis beatae assumenda earum similique eius? Sapiente
          laboriosam sit, pariatur facere qui eum dolores voluptates soluta
          inventore, quidem ad odio maxime accusamus consequuntur optio
          corporis, illum debitis asperiores praesentium exercitationem ab
          tenetur eaque! Non veritatis perferendis cum minima id accusantium
          sapiente repudiandae, quos ex reiciendis eaque earum dolore,
          temporibus quam numquam esse, officia possimus autem commodi.
          Laudantium labore suscipit aliquid, praesentium dolorem obcaecati
          voluptas unde omnis nesciunt consectetur minima laborum, quis beatae
          autem eveniet odio? Soluta similique maxime mollitia veniam et?
          Perspiciatis modi quasi, quaerat laborum unde doloribus aliquam illum
          magni veritatis expedita blanditiis.
        </p>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Limitation of Liability</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Minima eaque, totam delectus nemo
          ducimus fuga perferendis cumque neque possimus repellendus cum beatae
          dolore. Illo autem, id culpa ducimus qui earum rem esse deleniti
          doloribus distinctio labore maiores vitae totam cum quae voluptas
          quibusdam aperiam mollitia adipisci nulla aliquam sed. Exercitationem
          amet vel maiores laudantium blanditiis inventore expedita optio
          veniam. Exercitationem cumque ipsam saepe, ratione nostrum distinctio
          reprehenderit, consectetur aperiam necessitatibus totam doloremque
          animi? Non blanditiis veritatis neque ut sapiente sint maiores
          praesentium, vel libero provident hic iste nam quasi odit possimus
          quibusdam aut modi ipsam alias soluta sequi laborum molestiae
          molestias! Consectetur vel cumque corrupti voluptate quidem assumenda
          ipsam eligendi! Ea non magni hic adipisci reprehenderit officiis harum
          dicta. Maxime ut soluta corrupti iure facere ipsam, maiores sequi
          consectetur, dignissimos dolores at mollitia! Vitae nesciunt nostrum
          repellendus rem dolorum suscipit doloribus voluptates facere at
          officia veritatis est quisquam fugit distinctio ipsum harum culpa
          facilis dolor qui, corrupti dolore delectus eligendi ea soluta?
          Sapiente qui alias delectus, quas optio dolorum molestiae ipsa
          blanditiis perspiciatis placeat consequatur iure facere eos ea. Sint
          incidunt, neque consectetur suscipit atque corporis natus alias est
          perferendis saepe amet harum iure a, perspiciatis ea repellat soluta
          sunt tempora consequuntur veritatis totam. Quam fuga, beatae
          exercitationem, voluptas nostrum iure asperiores esse voluptatibus,
          quod eum quisquam. Perferendis aliquid quas tenetur voluptas nostrum
          iure facere tempore necessitatibus, esse ad minima eius fuga quo ex
          cupiditate! Consequuntur error odio nihil fugit minima, obcaecati
          quidem pariatur commodi similique nobis hic illo ab non est at!
          Repellendus voluptatibus provident hic facilis ipsam dicta incidunt
          eos labore error architecto repellat deserunt vero ullam doloremque in
          praesentium dolorum corporis omnis perspiciatis quasi, maiores
          laudantium adipisci vitae quos. Et, magnam? Provident quod a expedita
          commodi doloribus unde tempore. Culpa laborum ipsam dolor
          exercitationem, vero ducimus placeat nihil. Quis, magnam. Repudiandae
          corrupti quisquam optio, eum nihil illum odio porro assumenda
          voluptatum! Quia ut nulla ipsa delectus illo. Maiores odio optio rerum
          veritatis enim repellendus qui illum dolorum voluptatem quisquam.
          Necessitatibus alias ea veritatis. Magnam asperiores ut similique
          itaque? Eius voluptas numquam doloribus sint doloremque debitis
          corrupti, aspernatur minus voluptates iure, earum eos iste. Adipisci
          tempora vel veritatis cum, voluptatibus nam? Assumenda placeat in
          dolorem debitis, cupiditate non iusto ipsum nisi? Nesciunt iste qui
          natus consequuntur inventore illo voluptate velit nihil reprehenderit
          obcaecati, culpa quod quibusdam corporis veniam rerum aperiam,
          mollitia corrupti odit cupiditate ut facere. Ipsam, reprehenderit.
        </p>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Right to Amend</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically.
        </p>
      </div>

      <div className=" mt-[40px] text-center  text-3xl ">
        <h1>Your Privacy</h1>
      </div>

      <div className=" mt-5 text-justify    ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically.
        </p>
      </div>
    </div>
  );
};

export default TermsComditions;
