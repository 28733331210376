import { Route, Routes } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Main from "./Pages/Home/Main/Main";
import PublicRoutes from "./Routes/PublicRoutes";
import UserRoutes from "./Routes/UserRoutes";
import UserDashboard from "./Pages/UserDashboard/UserDashboard";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AdminRoutes from "./Routes/AdminRoutes";
import UserDashboardIndex from "./Component/UserDashboard/UserDashboardIndex";
import AdminDashboardIndex from "./Component/AdminDashboard/AdminDashboardIndex";
import DoctorDashboard from "./Pages/DoctroDashbaord/DoctorDashboard";
import DoctorRoutes from "./Routes/DoctorRoutes";
import DoctorDashboardIndex from "./Component/DoctorDashboard/DoctorDashboardIndex/DoctorDashboardIndex";
import { ToastContainer } from "react-toastify";
import SuperAdminDashboard from "./Pages/SuperAdminDashboard/SuperAdminDashboard";
import SuperAdminDashboardIndex from "./Component/SuperAdminDashboard/SuperAdminDashboardIndex";
import SuperAdminRoute from "./Routes/SuperAdminRoute";
import Notfound from "./Shared/Notfound";

function App() {
  return (
    <div className="App max-w-[1400px] mx-auto">
      <Routes>
        <Route path="*" element={<Notfound></Notfound>}></Route>
        <Route
          path="/"
          element={
            <>
              <Main />
            </>
          }
        >
          {PublicRoutes.map(({ path, Component }, index) => (
            <Route key={index + 45} path={path} element={<Component />} />
          ))}
        </Route>

        {/*Admin dashboard routes */}
        <Route path="/adminDashboard" element={<AdminDashboard />}>
          <Route index element={<AdminDashboardIndex />}></Route>
          {AdminRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        {/*Super Admin dashboard routes */}
        <Route path="/superAdminDashboard" element={<SuperAdminDashboard />}>
          <Route index element={<SuperAdminDashboardIndex />}></Route>
          {SuperAdminRoute.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        {/*user dashboard routes */}
        <Route path="/userDashboard" element={<UserDashboard />}>
          <Route index element={<UserDashboardIndex />}></Route>
          {UserRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>
        {/*user dashboard routes */}
        <Route path="/doctorDashboard" element={<DoctorDashboard />}>
          <Route index element={<DoctorDashboardIndex />}></Route>
          {DoctorRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
