import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Product = () => {
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [hasItemsInCart, setHasItemsInCart] = useState(false);

  useEffect(() => {
    fetch("https://servercareseebd.careseebd.com/api/v1/products")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Function to toggle the cart's visibility
  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const addToCart = (product) => {
    const updatedCart = [...cartItems];
    const existingItemIndex = updatedCart.findIndex(
      (item) => item._id === product._id
    );

    if (existingItemIndex !== -1) {
      // If the product is already in the cart, increase its quantity
      updatedCart[existingItemIndex].quantity += 1;
    } else {
      // If the product is not in the cart, add it with quantity 1
      updatedCart.push({ ...product, quantity: 1 });
    }

    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };

  const removeFromCart = (productId) => {
    const updatedCart = cartItems.filter((item) => item._id !== productId);
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };

  const increaseQuantity = (productId) => {
    const updatedCart = [...cartItems];
    const existingItemIndex = updatedCart.findIndex(
      (item) => item._id === productId
    );

    if (existingItemIndex !== -1) {
      updatedCart[existingItemIndex].quantity += 1;
      setCartItems(updatedCart);
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    }
  };

  const decreaseQuantity = (productId) => {
    const updatedCart = [...cartItems];
    const existingItemIndex = updatedCart.findIndex(
      (item) => item._id === productId
    );

    if (existingItemIndex !== -1) {
      if (updatedCart[existingItemIndex].quantity > 1) {
        updatedCart[existingItemIndex].quantity -= 1;
        setCartItems(updatedCart);
        localStorage.setItem("cartItems", JSON.stringify(updatedCart));
      } else {
        // If the quantity is 1, remove the item from the cart
        updatedCart.splice(existingItemIndex, 1);
        setCartItems(updatedCart);
        localStorage.setItem("cartItems", JSON.stringify(updatedCart));
      }
    }
  };

  const getTotalQuantity = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };
  console.log(products);
  return (
    <div>
      {/* Cart icon */}
      <button
        className="fixed top-20 right-0 bg-secondary p-2 rounded-l-full text-white z-50"
        onClick={toggleCart}
      >
        🛒 Cart ({cartItems.length})
      </button>

      {/* Cart content */}
      {isCartOpen && (
        <div className="fixed top-0 right-0 rounded-l-md h-auto lg:w-1/4 bg-secondary px-4 py-8 sm:px-6 lg:px-8 z-50">
          <button
            className="absolute top-4 right-4 text-gray-600 transition hover:scale-110"
            onClick={toggleCart}
          >
            <span className="sr-only bg-white">Close cart</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* Cart items */}
          <div className="max-h-60 overflow-y-auto">
            <ul className="space-y-4">
              {cartItems.map((item) => (
                <li key={item.id} className="flex items-center gap-4">
                  {/* Item details */}
                  <img
                    src={item.imageUrl} // Replace with the actual item image URL
                    alt=""
                    className="h-16 w-16 rounded object-cover"
                  />
                  <div className=" flex  justify-between gap-5">
                    <div>
                      <h3 className="text-sm text-white">{item.name}</h3>
                      <dl className="mt-0.5 space-y-px text-[10px] text-white">
                        <div>
                          <dt className="inline">Price: </dt>
                          <dd className="inline">{item.price} ৳ </dd>
                        </div>
                      </dl>
                      <button
                        className="text-red-500"
                        onClick={() => removeFromCart(item._id)}
                      >
                        Remove
                      </button>
                    </div>

                    <div className="flex items-center space-x-2">
                      <button
                        className="bg-primary text-white p-2 rounded"
                        onClick={() => decreaseQuantity(item._id)}
                      >
                        -
                      </button>
                      <span className="text-white">{item.quantity}</span>
                      <button
                        className="bg-primary text-white p-2 rounded"
                        onClick={() => increaseQuantity(item._id)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Cart actions */}
          <div className="space-y-4 text-center m-2">
            <Link
              className={`block rounded border border-white px-5 py-3 text-sm text-white transition hover:ring-1 hover:ring-gray-400 ${
                cartItems.length === 0 ? "pointer-events-none" : ""
              }`}
            >
              Total Product ({cartItems.length})
            </Link>

            {cartItems.length > 0 && (
              <Link
                to="/accessories/checkout"
                className="block rounded bg-primary px-5 py-3 text-sm text-white transition hover:bg-gray-600"
              >
                Checkout
              </Link>
            )}

            <a
              href="#"
              className="inline-block text-sm text-white underline underline-offset-4 transition hover:text-gray-600"
            >
              Continue shopping
            </a>
          </div>
        </div>
      )}

      <div className="m-12 md:grid md:grid-cols-3 sm:grid sm:grid-cols-1">
        {products.map((product) => (
          <div key={product.id} className="max-w-sm">
            <div className="group relative block overflow-hidden shadow-xl">
              <button className="absolute end-4 top-4 z-10 rounded-full bg-white p-1.5 text-gray-900 transition hover:text-gray-900/75">
                <span className="sr-only"> sale !</span>
                sale !
              </button>

              <img
                src={product.imageUrl}
                alt=""
                className="h-64 w-full object-cover transition duration-500 group-hover:scale-105 sm:h-72"
              />

              <div className="relative border border-gray-100 bg-white p-6">
                <div className="flex justify-between items-center">
                  <span className="whitespace-nowrap bg-yellow-400 text-black rounded-sm px-3 py-1.5 text-xs font-medium">
                    stock: {product.stock}
                  </span>
                  <Link to={`/accessories/product-details/${product._id}`}>
                    <Icon className="text-xl" icon="carbon:view"></Icon>
                  </Link>
                </div>

                <h3 className="mt-4 text-lg font-medium text-gray-900">
                  {product.name}
                </h3>

                <p className="mt-1.5 text-sm text-gray-700">
                  ৳ {product.price}
                </p>

                <div className="mt-4">
                  <button
                    onClick={() => addToCart(product)}
                    className="block w-full rounded bg-secondary text-white 0 p-4 text-sm font-medium transition hover:scale-105"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
