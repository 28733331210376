import React, { useState } from "react";
import DoctorBillOverview from "./DoctorBillOverview";
import DoctorTransactions from "./DoctorTransactions/DoctorTransactions";
import DoctorOrders from "./DoctorOrders/DoctorOrders";

const DoctorBilling = () => {
  const [tab, setTab] = useState("orders");
  return (
    <div>
      <DoctorBillOverview />

      <div className="p-5 bg-white rounded-lg shadow-md">
        <div className="grid grid-cols-2">
          <div className="flex justify-start items-center">
            <button
              onClick={() => setTab("orders")}
              className={
                tab !== "orders"
                  ? "rounded-l-md px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9]  duration-500"
                  : "rounded-l-md px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white duration-500"
              }
            >
              Orders
            </button>

            <button
              onClick={() => setTab("transactions")}
              className={
                tab !== "transactions"
                  ? "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9] duration-500"
                  : "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white  duration-500"
              }
            >
              Transactions
            </button>
          </div>
          {/* <button className="text-white hover:scale-105 rounded-md duration-300 bg-secondary px-3 w-fit block ml-auto font-semibold">
            GTS Details
          </button> */}
        </div>

        <div className="mt-10">
          {tab === "transactions" ? <DoctorTransactions /> : <DoctorOrders />}
        </div>
      </div>
    </div>
  );
};

export default DoctorBilling;
