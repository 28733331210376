import React from "react";
import logo from "../../Assets/logo.png";

import img from "../../Assets/Login/sign up 752.png";
import Login from "./Login";

const LoginSignUp = () => {
  return (
    <div className="flex justify-center items-center bg-white">
      <div className=" md:grid md:grid-cols-12 bg-bgColor rounded-2xl lg:w-10/12 w-11/12 my-10">
        <div className=" col-span-7">
          <div className="  flex items-center justify-between mr-5">
            <img className=" m-5" src={logo} alt="" />
          </div>

          <div className=" mt-1 m-5">
            <div className="flex justify-center items-center">
              <button
                className={
                  "rounded-md px-4 py-2 flex items-center justify-center font-semibold text-secondary text-2xl duration-500"
                }
              >
                Login
              </button>

              {/* <button
                onClick={() => setTab("signUp")}
                className={
                  tab !== "signUp"
                    ? "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9] duration-500"
                    : "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white  duration-500"
                }
              >
                SignUp
              </button> */}
            </div>

            <Login />
            {/* {tab === "login" ? <Login /> : <SignUp />} */}
          </div>
        </div>

        <div className=" col-span-5">
          <img className=" rounded-r-xl " src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LoginSignUp;
