import React from "react";
import AmirFaysal from "../../Assets/DeveloperTeam/Amir Faysal.png";
import ifty from "../../Assets/DeveloperTeam/ifty.png";
import leyon from "../../Assets/DeveloperTeam/Leyon.png";
import { Icon } from "@iconify/react";

const DeveloperTeam = () => {
  return (
    <div className=" min-h-screen">
      <div className=" text-center text-4xl  rounded-md mt-5 border mx-auto   max-w-sm p-1 text-secondary bg-primary font-bold bg-gradient-to-b from-primary to-teal-100 shadow-xl transition-opacity duration-500">
        <h1 className=" text-black">Lead Team</h1>
      </div>

      <div className="flex  justify-center items-center mb-5">
        <div className=" lg:grid lg:grid-cols-3   gap-5 mt-5">
          <div className="flex flex-col items-center justify-center shadow-xl p-5 w-[320px] h-[322px]  text-center rounded-md bg-white bg-gradient-to-b from-primary to-teal-100  transition-opacity duration-500">
            <img src={AmirFaysal} alt="" className="mx-auto" />
            <h1 className="text-2xl  font-bold">Amir Faysal</h1>
            <p className="    ">Developer</p>

            <div className="flex justify-center items-center gap-2 mt-2 ">
              <a
                href="https://www.linkedin.com/in/amir-faysal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon="line-md:linkedin"
                  className="text-[#0A66C2] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>

              <a href="mailto:amirfaysal0471@gmail.com">
                <Icon
                  icon="line-md:email-twotone"
                  className="text-[#3b5998] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>

              <a
                href="https://www.facebook.com/amirfaysal047"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon="line-md:facebook"
                  className="text-[#3b5998] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>
            </div>
          </div>

          <div className=" flex flex-col items-center justify-center shadow-xl p-5 w-[320px] h-[322px]  rounded-md text-center bg-white bg-gradient-to-b from-primary to-teal-100  transition-opacity duration-500">
            <img src={ifty} alt="" />
            <h1 className=" text-xl font-bold">Ashikul Islam Ifty</h1>
            <p>Developer</p>

            <div className="flex justify-center items-center gap-2 mt-2">
              <a
                href="https://www.linkedin.com/in/yourprofile/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon="line-md:linkedin"
                  className="text-[#0A66C2] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>

              <a href="mailto:youremail@example.com">
                <Icon
                  icon="line-md:email-twotone"
                  className="text-[#3b5998] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>

              <a
                href="https://www.facebook.com/yourprofile/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon="line-md:facebook"
                  className="text-[#3b5998] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center w-[320px] h-[322px]  rounded-md  shadow-xl p-5 text-center bg-white bg-gradient-to-b from-primary to-teal-100  transition-opacity duration-500">
            <img src={leyon} alt="" />
            <h1 className=" text-xl font-bold">Hadiuzzaman Leon</h1>
            <p>UI/UX Desinger</p>
            <div className="flex justify-center items-center gap-2 mt-2">
              <a
                href="https://www.linkedin.com/in/yourprofile/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon="line-md:linkedin"
                  className="text-[#0A66C2] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>

              <a href="mailto:youremail@example.com">
                <Icon
                  icon="line-md:email-twotone"
                  className="text-[#3b5998] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>

              <a
                href="https://www.facebook.com/yourprofile/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon="line-md:facebook"
                  className="text-[#3b5998] transition-transform transform hover:scale-110 cursor-pointer text-xl"
                ></Icon>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* project Overview */}

      <div className=" m-10">
        <div className=" text-center">
          {" "}
          <h1 className=" text-4xl font-bold">Care See</h1>
        </div>
        <div className=" mt-5 text-justify ">
          <p>
            The Care See project stands as a testament to our dedication to
            revolutionizing eye care. Designed to assist eye specialists in
            monitoring and diagnosing their patients more efficiently, this
            project brings a unique blend of technology and healthcare together.
          </p>
        </div>

        <div>
          <h1 className=" text-xl   font-bold">
            Project Leaders and Key Players
          </h1>
          <p className=" text-justify">
            Amir Faysal: Serving as the backbone of the project's development,
            Amir meticulously planned each phase. As the main developer, he was
            responsible for the implementation of key features and ensuring the
            seamless performance of the system.
          </p>
        </div>

        <div>
          <p className=" text-justify">
            Ashiqul Islam Ifty: A creative force, Ashiqul took charge of
            designing the Care C system, ensuring its user-friendliness and
            modern aesthetic complemented its groundbreaking functionality.
          </p>
        </div>

        <div>
          <p className=" text-justify">
            Hadiuzzaman Leon: As the lead, Leon oversaw everything from the
            initial system design to the execution stages, ensuring that the
            project's goals were met and that it adhered to the highest
            standards of quality.
          </p>
        </div>

        <div>
          <h1 className=" text-xl   font-bold">Objective</h1>
          <p className=" text-justify">
            {" "}
            The primary goal of the Care see project is to revolutionize the way
            eye specialists interact with their patients. By offering a series
            of games designed specifically for eye patients, we can gather
            significant data based on their performance. These games, meant to
            be played over a span of 180 days, generate insights that can help
            determine the specific issues a patient might have, effectively
            cutting down the diagnosis time.
          </p>
        </div>
        <div>
          <h1 className=" text-xl   font-bold">Innovation at Its Best</h1>
          <p className=" text-justify">
            {" "}
            In the modern age, getting accurate data quickly is imperative. With
            Care see, patients can be monitored online, allowing doctors to
            assess their performance in the games, thereby understanding the
            condition of their eyes. This real-time data collection and
            assessment mean patients can receive timely treatments based on
            accurate diagnoses.
          </p>
        </div>
        <div>
          <h1 className=" text-xl   font-bold">Innovation at Its Best</h1>
          <p className=" text-justify">
            {" "}
            Quick Monitoring: With online tracking, doctors can keep a close eye
            on their patient's progress, ensuring timely interventions when
            necessary.
          </p>
          <p className=" text-justify">
            Accurate Diagnoses: By analyzing a patient's performance in the
            games, specialists can pinpoint specific issues, leading to more
            effective treatments.{" "}
          </p>
          <p className=" text-justify">
            User Engagement: The incorporation of games ensures patients remain
            engaged throughout the process, making data collection not just
            effective but enjoyable.
          </p>
        </div>
        <div>
          <h1 className=" text-xl   font-bold"> Conclusion</h1>
          <p className=" text-justify">
            {" "}
            The Care see project exemplifies how technology can be used to push
            the boundaries of healthcare. With a dedicated team and a clear
            vision, we have created a platform that stands to make a genuine
            difference in the world of ophthalmology.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeveloperTeam;
