import React, { useState } from "react";
import AdminPatientClinicalTable from "./AdminPatientClinicalTable";
import { useEffect } from "react";
import { server_url } from "../../../../Config/API";

const AdminPatientClinicalFinding = ({ patientId }) => {
  const [patients, setPatients] = useState([]);
  useEffect(() => {
    fetch(
      `${server_url}/findings/get/specific?fieldName=${"userId"}&&fieldValue=${patientId}`
    ).then((res) => res.json().then((data) => setPatients(data?.data)));
  }, [patientId]);

  return (
    <div>
      <div className="w-full overflow-x-auto mt-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto "
          cellspacing="0"
        >
          <tbody className="">
            <tr className="bg-gray-50 ">
              <th></th>
              <th></th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                SPH <br />
                <span className="text-gray-400 text-xs">(-25 to +25)</span>
              </th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                CLY <br />
                <span className="text-gray-400 text-xs">(-25 to +25)</span>
              </th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                Axis <br />
                <span className="text-gray-400 text-xs">(0° to 180°)</span>
              </th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                Add <br />
                <span className="text-gray-400 text-xs">(+0.75 - +5.00)</span>
              </th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                Distance
              </th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                LOG MAR
              </th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                Near
              </th>
              <th
                scope="col"
                className="p-2 px-6 text-sm font-medium stroke-slate-700 text-slate-700 "
              >
                LOG MAR
              </th>
            </tr>

            {patients?.map((patient) => (
              <AdminPatientClinicalTable key={patient?._id} patient={patient} />
            ))}
          </tbody>
        </table>
        {patients?.length || (
          <h2 className="w-full text-xl font-semibold text-red-500 text-center my-10">
            Clinical Finding Not Found !
          </h2>
        )}
      </div>
    </div>
  );
};

export default AdminPatientClinicalFinding;
