import { Switch } from "@headlessui/react";
import { Icon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import bcrypt from "bcryptjs";
import UpdateHooks from "../../../../Hooks/UpdateHooks";

const AdminPatientGeneral = ({ patientId, patient }) => {
  console.log(patientId);
  const [account, setAccount] = useState(patient?.isActive);
  const [selectedOption, setSelectedOption] = useState(patient?.accountType);
  const [password, setPassword] = useState("");

  const generatePassword = (event) => {
    event.preventDefault(); // Prevent the form from submitting

    // Basic password generation logic.
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let generatedPassword = "";
    for (let i = 0; i < 8; i++) {
      // Generates an 8-character password
      generatedPassword += chars[Math.floor(Math.random() * chars.length)];
    }
    setPassword(generatedPassword);
  };

  console.log(patient);

  const handelUpdate = async (e) => {
    e.preventDefault();
    const name = e.target?.name.value;
    const email = e.target?.email.value;
    const dateOfBirth = e.target?.dateOfBirth.value;
    const phone = e.target?.phone.value;
    const hashedPassword = bcrypt.hashSync(password);

    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${patientId}`,
      {
        password: password ? hashedPassword : patient?.password,
        name: name,
        phone: phone,
        dateOfBirth: dateOfBirth,
        email: email,
      }
    );
    toast.success("Data updated !");
  };

  const handelUpdateAccount = async () => {
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${patientId}`,
      {
        isActive: !account,
      }
    );
    toast.success("Data updated !");
  };
  const handelUpdateAccountType = async () => {
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${patientId}`,
      {
        accountType: selectedOption ? selectedOption : "none",
      }
    );
    toast.success("Data updated !");
  };

  console.log(selectedOption);
  return (
    <div className="">
      <div className=" bg-white p-5 mt-5 rounded-md">
        {/* General Div */}

        <div>
          <div className=" flex justify-between items-center">
            <div className="mt-2 flex items-center">
              <Switch
                checked={account}
                onClick={handelUpdateAccount}
                onChange={setAccount}
                className={`${account ? "bg-secondary/80" : "bg-secondary/30"}
          relative inline-flex h-6 w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${account ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <label
                class="inline-block pl-2 text-lg font-semibold text-black"
                for="flexSwitchCheckDefault"
              >
                Account
              </label>
            </div>

            <div className="flex gap-5 items-center justify-center">
              <div>
                <input
                  type="radio"
                  id="convergence"
                  name="option"
                  checked={selectedOption === "convergence"}
                  value="convergence"
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <label htmlFor="convergence" className="ml-2">
                  Convergence
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  id="divergence"
                  name="option"
                  checked={selectedOption === "divergence"}
                  value="divergence"
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <label htmlFor="divergence" className="ml-2">
                  Divergence
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  id="none"
                  name="option"
                  checked={selectedOption === "none"}
                  value="none"
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <label htmlFor="none" className="ml-2">
                  None
                </label>
              </div>

              <button
                onClick={handelUpdateAccountType}
                className="px-3 py-1 rounded-md  bg-secondary text-white hover:bg-secondary/90"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* second div */}

      <div className=" mt-3">
        <form onSubmit={handelUpdate}>
          <div className="relative flex items-center mb-4">
            <input
              type="text"
              placeholder="Name"
              name="name"
              className="border p-2 w-full pl-10"
              defaultValue={patient?.name}
            />
            <Icon
              icon="mdi:user"
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
            />
          </div>

          <div className="relative flex items-center mb-4">
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="border p-2 w-full pl-10"
              defaultValue={patient?.email}
            />
            <Icon
              icon="ic:baseline-email"
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
            />
          </div>

          <div className="relative flex items-center mb-4">
            <input
              type="date"
              name="dateOfBirth"
              placeholder="Date of birth"
              className="border p-2 w-full pl-10"
              defaultValue={patient?.dateOfBirth}
            />
            <Icon
              icon="ph:calendar-fill"
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
            />
          </div>
          <div className="relative flex items-center mb-4">
            <input
              type="tel"
              name="phone"
              placeholder="Phone number"
              className="border p-2 w-full pl-10"
              defaultValue={patient?.phone}
            />
            <Icon
              icon="fluent:call-20-filled"
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
            />
          </div>
          <div className="relative">
            <input
              type="text"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Type or generate password"
              className="border p-2 w-full pl-24 pr-2"
            />
            <button
              onClick={generatePassword}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-secondary text-white hover:bg-secondary/90 rounded-l-md"
            >
              Generate
            </button>
          </div>

          <button
            type="submit"
            className="px-3 py-2 mt-6 rounded-md bg-secondary text-white hover:bg-secondary/90 hover:scale-105 duration-300 block ml-auto "
          >
            Update !
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminPatientGeneral;
