import React, { useState } from "react";
import swal from "sweetalert";
import AuthUser from "../../Hooks/authUser";
import Loading from "../../Shared/Loading";
import { useNavigate } from "react-router";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Login = () => {
  const { http, setToken } = AuthUser();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const issueDate = moment().format("YYYY-MM-DD");

  const getPackage = async (user, id) => {
    setLoading(true);
    console.log(id);
    await fetch(
      `https://servercareseebd.careseebd.com/api/v1/package/specific?fieldName=${"userId"}&&fieldValue=${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data?.data?.length) {
          const isExpire = data?.data?.[0]?.expireDate
            ? moment(issueDate).isAfter(data?.data?.[0]?.expireDate)
            : true;
          if (!isExpire) {
            navigate(`/${user?.role}Dashboard`);
            window.location.reload();
          } else {
            if (!loading) {
              toast.error("You dose't have any package ! :(");
              navigate(`/`);
              window.location.reload();
            }
          }
          setLoading(false);
        }
        setLoading(false);
      });
  };

  const handelSubmit = async (e) => {
    // setLoading(true)
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    await http
      .post("/user/login", { email: email, password: password })
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Login Successfully");
          setToken(
            res.data.data.user.email,
            res.data.data.token,
            res.data.data.user.role,
            res.data.data.user,
            res.data.data.userIp
          );
          if (res.data?.data?.data?.role === "user") {
            const d = res?.data?.data?.data;
            getPackage(d, d?._id);
          } else {
            navigate(`/${res.data?.data?.data?.role}Dashboard`);
            window.location.reload();
            setLoading(false);
          }

          // window.location.reload();
        } else {
          // console.log("rrrrrr");
        }
      })
      .catch((err) => {
        console.log("Error", err.response.data.message);
        swal("Error", `${err.response.data.message}`, "error");
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <form
      onSubmit={handelSubmit}
      className=" lg:grid lg:grid-cols-1 gap-2 text-center mx-10"
    >
      <p className=" text-center text-xs mt-1  opacity-50">
        {" "}
        Login to use our special features
      </p>
      <div className="">
        <label
          htmlFor="email"
          className="block text-left text-sm font-bold  text-gray-700"
        >
          E-mail
        </label>
        <input
          name="email"
          placeholder="Enter your E-mail"
          className="py-2 p-3 rounded-md shadow-md mt-2 w-full"
          type="email"
        />
      </div>

      <div className=" mt-2">
        <label
          htmlFor="password"
          className="block  font-bold text-left text-sm  text-gray-700"
        >
          Password
        </label>
        <input
          name="password"
          placeholder="Enter your Password"
          className="py-2 p-3 rounded-md shadow-md mt-2 w-full"
          type="password"
        />
      </div>
      <Link
        className="text-left text-sm text-secondary underline"
        to="/resetPassword"
      >
        Super Admin Forget password
      </Link>
      <div className=" mt-2 ">
        <div className="  justify-start  items-center flex gap-2">
          <button
            type="submit"
            className=" bg-secondary text-white px-4 py-2 mt-3 rounded-md  font-semibold hover:scale-105 duration-300"
          >
            Log in
          </button>
        </div>
      </div>
    </form>
  );
};

export default Login;
