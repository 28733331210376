import { Icon } from "@iconify/react";
import React, { useState } from "react";
import AuthUser from "../../../Hooks/authUser";
import { useEffect } from "react";
import moment from "moment/moment";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import PostHooks from "../../../Hooks/PostHooks";
import { toast } from "react-toastify";
import Payment from "../DoctorBilling/Payment";

const LicensePatient = ({ patientId, patient, packages }) => {
  const { userInfo } = AuthUser();
  const [user, setUser] = useState([]);
  const [pack, setPack] = useState("");
  const [anaglyph, setAnaglyph] = useState(false);

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pinCode, setPinCode] = useState("");

  const today = moment();
  const issueDate = moment().format("YYYY-MM-DD");
  const expireDate = today.add(pack ? pack : 1, "days").format("YYYY-MM-DD");
  console.log(issueDate, packages?.expireDate);
  const isExpire = packages?.expireDate
    ? moment(issueDate).isAfter(packages?.expireDate)
    : true;
  const packagePrice = `${
    (pack === 1 && user?.oneDay ? user?.oneDay : 0) ||
    (pack === 30 && user?.oneMonth ? user?.oneMonth : 0) ||
    (pack === 90 && user?.threeMonth ? user?.threeMonth : 0) ||
    (pack === 180 && user?.sixMonth ? user?.sixMonth : 0)
  }`;
  const totalPrice = Number(
    (pack && packagePrice ? +packagePrice : 0) +
      (anaglyph && user?.anaglyphCost ? +user?.anaglyphCost : 0)
  );

  const isDisable = anaglyph
    ? (address1 && address2 && city && state && pinCode && country) == false
    : false;

  useEffect(() => {
    fetch(`https://servercareseebd.careseebd.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setUser(data?.data));
  }, []);

  const data = {
    userName: patient?.name,
    userEmail: patient?.email,
    userId: patientId,

    referralName: user?.name,
    referralEmail: user?.email,
    referralId: user?._id,
    paymentType: "Cash On Delivery",

    packageName: `${pack} Days Package`,
    packagePrice: Number(packagePrice),
    Anaglyph: anaglyph ? "Yes" : "No",
    anaglyphPrice: (anaglyph ? user?.anaglyphPrice : 0)
      ? user?.anaglyphPrice
      : 0,
    totalDay: pack,
    totalPrice: totalPrice,
    address: {
      city,
      state,
      country,
      address1,
      address2,
      pinCode,
    },

    issueDate: issueDate,
    expireDate: expireDate,
  };

  const handelUpdate = async (pay) => {
    if (packages?.expireDate) {
      await UpdateHooks(
        `https://servercareseebd.careseebd.com/api/v1/package/updatePackage/${packages?._id}`,
        data
      );
      toast?.success(`The package was Updated !`);
    } else {
      await PostHooks(
        `https://servercareseebd.careseebd.com/api/v1/package/addPackage`,
        data,
        `The package was given to ${patient?.name} !`
      );
    }
  };

  return (
    <div className=" mt-5">
      {isExpire && (
        <div className="flex gap-5 items-center flex-wrap justify-start mt-10">
          {user?.oneDayToggle && (
            <div>
              <button
                onClick={() => setPack(1)}
                className={
                  pack === 1
                    ? "text-md text-white px-8 py-2  bg-secondary rounded-md"
                    : "text-md text-secondary px-8 py-1.5 border-2 border-secondary rounded-md hover:bg-secondary duration-300 hover:text-white"
                }
              >
                1 day
              </button>
            </div>
          )}
          {user?.oneMonthToggle && (
            <div>
              <button
                onClick={() => setPack(30)}
                className={
                  pack === 30
                    ? "text-md text-white px-8 py-2  bg-secondary rounded-md"
                    : "text-md text-secondary px-8 py-1.5 border-2 border-secondary rounded-md hover:bg-secondary duration-300 hover:text-white"
                }
              >
                1 Month
              </button>
            </div>
          )}
          {user?.threeMonthToggle && (
            <div>
              <button
                onClick={() => setPack(90)}
                className={
                  pack === 90
                    ? "text-md text-white px-8 py-2  bg-secondary rounded-md"
                    : "text-md text-secondary px-8 py-1.5 border-2 border-secondary rounded-md hover:bg-secondary duration-300 hover:text-white"
                }
              >
                3 Month
              </button>
            </div>
          )}
          {user?.sixMonthToggle && (
            <div>
              <button
                onClick={() => setPack(180)}
                className={
                  pack === 180
                    ? "text-md text-white px-8 py-2  bg-secondary rounded-md"
                    : "text-md text-secondary px-8 py-1.5 border-2 border-secondary rounded-md hover:bg-secondary duration-300 hover:text-white"
                }
              >
                6 Month
              </button>
            </div>
          )}
        </div>
      )}
      {isExpire && (
        <div class="my-5">
          <label className=" flex justify-start items-center">
            <input
              checked={anaglyph}
              onChange={(e) => setAnaglyph(e.target.checked)}
              type="checkbox"
              class="w-5 h-6"
            />
            <span class="ml-2 text-md font-semibold opacity-60">Anaglyph</span>
          </label>
        </div>
      )}
      {packages?.expireDate && (
        <div className="my-5 flex items-center gap-3">
          <div className="w-4 h-4 rounded-full bg-secondary shadow-primary/50 shadow-lg"></div>{" "}
          <p className="opacity-60">{packages?.packageName}</p>
        </div>
      )}

      <label className="text-sm block mb-1 opacity-60">
        {packages?.expireDate ? "Expire Date" : "Today Date"}
      </label>
      <div className="w-full py-3 px-5 rounded-lg border flex items-center gap-3 opacity-60">
        <Icon icon="uiw:date" />{" "}
        <p>{packages?.expireDate ? packages?.expireDate : issueDate}</p>
      </div>

      <div className="  grid grid-cols-12  mt-10 gap-5 font-thin">
        {(anaglyph || pack) && (
          <div className=" lg:col-span-8 md:col-span-7 col-span-12 mr-5 border overflow-x-auto rounded-lg p-5 w-full">
            <table
              className="w-full text-left rounded w-overflow-x-auto    "
              cellspacing="0"
            >
              <tbody className=" text-left ">
                <tr>
                  <th
                    scope="col"
                    className="h-16 px-6  text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="h-16 px-6  text-sm font-medium stroke-slate-700 text-slate-700"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="h-16 px-6  text-sm font-medium stroke-slate-700 text-slate-700 "
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700"
                  >
                    Action
                  </th>
                </tr>
                {pack && (
                  <tr className=" border shadow rounded-lg">
                    <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                      {pack} Days Package
                    </td>
                    <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                      1
                    </td>
                    <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                      ৳{" "}
                      <span id="price">
                        {pack === 1 && user?.oneDay}
                        {pack === 30 && user?.oneMonth}
                        {pack === 90 && user?.threeMonth}
                        {pack === 180 && user?.sixMonth}
                      </span>
                    </td>

                    <td className="h-16 px-6 text-2xl transition duration-300 hover:cursor-pointer ">
                      <Icon
                        onClick={() => setPack("")}
                        icon="mingcute:delete-line"
                      ></Icon>
                    </td>
                  </tr>
                )}
                <div className="h-5"></div>
                {anaglyph && (
                  <tr className=" border shadow rounded-lg">
                    <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                      Anaglyph
                    </td>
                    <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                      1
                    </td>
                    <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                      ৳ {user?.anaglyphCost ? user?.anaglyphCost : 0}
                    </td>

                    <td className="h-16 px-6 text-2xl transition duration-300 hover:cursor-pointer ">
                      <Icon
                        onClick={() => setAnaglyph(false)}
                        icon="mingcute:delete-line"
                      ></Icon>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {anaglyph && (
              <div className="text-sm my-5 grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
                <div>
                  <label className="block mb-1">Address Line 1</label>
                  <input
                    name="address"
                    onChange={(e) => setAddress1(e.target.value)}
                    className="border py-2 px-4 rounded-lg w-full focus:outline-none"
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1">Address Line 2</label>
                  <input
                    name="address"
                    onChange={(e) => setAddress2(e.target.value)}
                    className="border py-2 px-4 rounded-lg w-full focus:outline-none"
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1">Town/City</label>
                  <input
                    name="city"
                    onChange={(e) => setCity(e.target.value)}
                    className="border py-2 px-4 rounded-lg w-full focus:outline-none"
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1">State</label>
                  <input
                    name="state"
                    onChange={(e) => setState(e.target.value)}
                    className="border py-2 px-4 rounded-lg w-full focus:outline-none"
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1">Country</label>
                  <input
                    name="country"
                    onChange={(e) => setCountry(e.target.value)}
                    className="border py-2 px-4 rounded-lg w-full focus:outline-none"
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1">PinCode</label>
                  <input
                    name="pinCode"
                    onChange={(e) => setPinCode(e.target.value)}
                    className="border py-2 px-4 rounded-lg w-full focus:outline-none"
                    type="number"
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="space-y-5 lg:col-span-4 md:col-span-5 col-span-12">
          {anaglyph && (
            <div className=" w-full border rounded-lg p-7 ">
              <div>
                <h1 className=" text-xl font-normal ">Address</h1>
              </div>
              <div class="border-t mt-5 mb-5 border-gray-300"></div>
              <div className="mt-5">
                <p>{address1}</p>
                <p>{address2}</p>
                <p>
                  {city} , {state}
                </p>
                <p>
                  {country} , {pinCode}
                </p>
              </div>
            </div>
          )}
          {(pack || anaglyph) && (
            <div className=" w-full  border rounded-lg p-7 ">
              <div>
                <h1 className=" text-xl font-normal ">Price Details</h1>
                <div className=" justify-between flex">
                  <h1>Total</h1>
                  <h1> ৳ {totalPrice}</h1>
                </div>
              </div>
              <div class="border-t mt-5 mb-5 border-gray-300"></div>

              <div class="flex justify-center items-center mt-5">
                {totalPrice ? (
                  <div className="flex gap-3">
                    <Payment
                      data={data}
                      packages={packages}
                      patient={patient}
                      isDisable={isDisable}
                    />

                    <button
                      onClick={() => handelUpdate("Cash On Delivery")}
                      disabled={isDisable}
                      className=" bg-secondary font-semibold hover text-white px-5 py-2 hover:scale-105 duration-300 rounded-md disabled:bg-secondary/50 disabled:scale-100 disabled:cursor-not-allowed whitespace-nowrap"
                    >
                      Cash On Delivery
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => handelUpdate()}
                    disabled={isDisable}
                    className=" bg-secondary font-semibold hover text-white px-5 py-2 hover:scale-105 duration-300 rounded-md disabled:bg-secondary/50 disabled:scale-100 disabled:cursor-not-allowed"
                  >
                    Continue
                  </button>
                )}
              </div>
              {(anaglyph
                ? (address1 &&
                    address2 &&
                    city &&
                    state &&
                    pinCode &&
                    country) == false
                : false) && (
                <p className="w-[70%] text-center mx-auto">
                  Please Complete Address to Continue
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LicensePatient;
