import AdminDashboardDoctors from "../Component/AdminDashboard/AdminDashboardDoctors/AdminDashboardDoctors";
import AdminDashboardIndex from "../Component/AdminDashboard/AdminDashboardIndex";
import AdminDoctorPatient from "../Component/AdminDashboard/AdminDoctorPatient/AdminDoctorPatient";
import AdminDoctorPatientReport from "../Component/AdminDashboard/AdminDoctorPatient/AdminDoctorPatientReport";
import ChangePassword from "../Component/UserDashboard/UserDashboardEditProfile/ChangePassword";
import EditProfile from "../Component/UserDashboard/UserDashboardEditProfile/EditProfile";

const AdminRoutes = [
  { path: "overview", Component: AdminDashboardIndex },
  { path: "doctors", Component: AdminDashboardDoctors },
  { path: "doctorsPatient/:id", Component: AdminDoctorPatient },
  { path: "doctorsPatientReport/:id", Component: AdminDoctorPatientReport },
  { path: "changePassword", Component: ChangePassword },
  { path: "editProfile", Component: EditProfile },
];

export default AdminRoutes;
