import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch data for the specific product using the productId
    fetch(`https://servercareseebd.careseebd.com/api/v1/products/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data); // Set the fetched data to the 'product' state
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, [id]);

  return (
    <div className=" min-h-screen">
      <header className="bg-blue-500 p-4 text-white">
        <h1 className="text-2xl font-semibold">Product Details</h1>
      </header>

      {product ? (
        <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 m-5">
          <div class=" rounded-lg bg-gray-100 shadow-2xl">
            <img src={product.imageUrl} alt="" />
          </div>
          <div class="h-auto rounded-lg bg-gray-100 lg:col-span-2 shadow-2xl p-5">
            <div className=" bg-secondary w-auto p-5 rounded-md  text-start text-white">
              <h1 className="   rounded-md text-white "> {product?.name}</h1>
              <h1> ৳ {product?.price}</h1>
              <h1> Stock: {product?.stock}</h1>
              <h1> Weight: {product?.weight} g</h1>
              <h1>Dimensions: {product?.dimensions} cm</h1>
            </div>
            <div>
              <p className=" text-justify">
                {" "}
                Description: {product?.description}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ProductDetails;
