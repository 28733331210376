import React from "react";

const AdminPatientClinicalTable = ({ patient }) => {
  return (
    <>
      {/* left */}
      <tr className="bg-[#F4F4F4] mt-4">
        <td
          rowSpan="2"
          className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 "
        >
          {patient?.date}
        </td>
        <td className="py-3 font-bold px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          Left
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.sph ? patient?.leftEye?.sph : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.cyl ? patient?.leftEye?.cyl : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.axis ? patient?.leftEye?.axis : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.add ? patient?.leftEye?.add : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.distance ? patient?.leftEye?.distance : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.logMar1 ? patient?.leftEye?.logMar1 : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.near ? patient?.leftEye?.near : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.leftEye?.logMar2 ? patient?.leftEye?.logMar2 : "-"}
        </td>
      </tr>

      {/* right */}
      <tr>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 font-bold">
          Right
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.sph ? patient?.rightEye?.sph : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.cyl ? patient?.rightEye?.cyl : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.axis ? patient?.rightEye?.axis : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.add ? patient?.rightEye?.add : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.distance ? patient?.rightEye?.distance : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.logMar1 ? patient?.rightEye?.logMar1 : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.near ? patient?.rightEye?.near : "-"}
        </td>
        <td className="py-3  px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
          {patient?.rightEye?.logMar2 ? patient?.rightEye?.logMar2 : "-"}
        </td>
      </tr>
    </>
  );
};

export default AdminPatientClinicalTable;
