import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import DoctorAllPatientsTable from "./DoctorAllPatientsTable";
import { Link } from "react-router-dom";
import AuthUser from "../../../Hooks/authUser";
import Loading from "../../../Shared/Loading";

const DoctorDashboardIndex = () => {
  const { userInfo } = AuthUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [patients, setPatients] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selected, setSelected] = useState("");
  const [filterData, setFilterData] = useState([]);

  const patientLenght = patients.length;
  useEffect(() => {
    setLoading(true);
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"referralId"}&&fieldValue=${
        userInfo?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data?.data);
        setPatients(data?.data);
        setFilterData(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [userInfo?._id]);

  const filter = patients?.filter((user) => {
    const activity = user?.isActive ? "active" : "inActive";
    return (
      (searchInput &&
        user?.name?.toLowerCase()?.includes(searchInput?.toLowerCase())) ||
      (selected && activity === selected) ||
      (selected === "all" && (activity === "active" || "inActive"))
    );
  });
  useEffect(() => {
    setFilterData(filter);
  }, [searchInput, selected]);

  if (loading) {
    return <Loading />;
  }
  if (error) return <div>Error: {error}</div>;
  return (
    <div>
      {/* doctor overview */}
      <div className="my-5 grid md:grid-cols-3  grid-cols-1 gap-5">
        <div className="bg-white p-5 rounded-md shadow-md">
          <div className="flex justify-between">
            <h2 className="text-lg font-semibold">Patients:</h2>
            <button className="bg-secondary text-white font-semibold px-3 rounded-md">
              {patientLenght}
            </button>
          </div>
          <p className="text-sm  mt-2">
            Total number of patients that you have added.
          </p>
        </div>
        <div className="bg-white p-5 rounded-md shadow-md">
          <div className="flex justify-between">
            <h2 className="text-lg font-semibold">Wallet:</h2>
            <button className="bg-secondary text-white font-semibold px-3 rounded-md">
              <span className="text-lg">৳</span> 0.0
            </button>
          </div>
          <p className="text-sm  mt-2">
            Amount available in your CareSee wallet.
          </p>
        </div>
        <div className="bg-white p-5 rounded-md shadow-md">
          <div className="flex justify-between">
            <h2 className="text-lg font-semibold">Due Amount:</h2>
            <button className="bg-secondary text-white font-semibold px-3 rounded-md">
              <span className="text-lg">৳</span> 0.0
            </button>
          </div>
          <p className="text-sm  mt-2">
            Pending amount you have to pay to CareSee
          </p>
        </div>
      </div>

      {/* add patient & all patient */}
      <div className="p-5 bg-white ">
        <div className="grid grid-cols-2 gap-10">
          <div>
            <Link to="/doctorDashboard/doctor-add-new-patient">
              {" "}
              <button className="bg-secondary py-2 px-4 rounded-md flex items-center text-white gap-2 hover:scale-105 duration-300 shadow-lg shadow-secondary/50">
                <Icon className="text-lg" icon="typcn:plus" />{" "}
                <span className="hidden md:block">Add New Patient</span>
              </button>
            </Link>
          </div>
          <div className="flex gap-5 justify-end text-sm md:text-base relative">
            <select
              onChange={(e) => setSelected(e.target.value)}
              className="border border-secondary px-3 rounded-lg"
              name="patientType"
            >
              <option value="all">All Patient</option>
              <option value="active">Active Patient</option>
              <option value="inActive">Inactive Patient</option>
            </select>
            <input
              onChange={(e) => setSearchInput(e.target.value)}
              className="border border-secondary rounded-lg px-3  py-2 focus:outline-none"
              type="search"
              name="search"
              placeholder="search "
            />
          </div>
        </div>

        {/* all patients */}
        <div className="w-full overflow-x-auto mt-10">
          <table
            className="w-full text-left rounded w-overflow-x-auto "
            cellspacing="0"
          >
            <tbody>
              <tr>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Action
                </th>
              </tr>

              <DoctorAllPatientsTable patientsData={filterData} />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DoctorDashboardIndex;
