import React from "react";

const Presentation = () => {
  return (
    <div className=" mb-[50px]  ">
      <div className=" text-center ml-[90px] mr-[90px] mt-[50px] ">
        <h1 className=" lg:text-3xl  text-secondary font-bold">
          {" "}
          CareSee Presentations
        </h1>
      </div>

      <div>
        <div class="fter:h-px  my-10 flex items-center before:h-px before:flex-1  before:bg-gray-300 before:content-[''] after:h-px after:flex-1 after:bg-gray-300  after:content-['']"></div>
      </div>

      <div className=" text-center ml-[90px] mr-[90px]  ">
        <div className=" lg:flex items-center gap-10 justify-center ">
          <div className=" mt-2">
            <button className=" border-4 p-5  border-secondary lg:text-2xl  rounded-3xl ">
              Dont't say no to Amblyopia
            </button>
          </div>
          <div className="mt-2">
            <button className=" border-4 lg:text-2xl border-secondary rounded-3xl p-5">
              {" "}
              How Do We See?
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
