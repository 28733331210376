import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useState } from "react";
import AdminPatientGeneral from "./AdminPatientGeneral";
import AdminPatientClinicalFinding from "./AdminPatientClinicalFinding";
import AdminPatientLicense from "./AdminPatientLicense";

const AdminEditDoctorPatient = ({ isOpen, closeModal, patient }) => {
  const [tab, setTab] = useState("general");
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className=" lg:w-8/12 md:10/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-gray-100 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Patient Details</h2>
                  <button
                    onClick={closeModal}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>
                <div className=" p-5">
                  <div className="flex justify-start items-center">
                    <button
                      onClick={() => setTab("general")}
                      className={
                        tab !== "general"
                          ? "rounded-l-md px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9]  duration-500"
                          : "rounded-l-md px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white duration-500"
                      }
                    >
                      General
                    </button>
                    <button
                      onClick={() => setTab("license")}
                      className={
                        tab !== "license"
                          ? " px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9]  duration-500"
                          : " px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white duration-500"
                      }
                    >
                      License
                    </button>

                    <button
                      onClick={() => setTab("clinicalFinding")}
                      className={
                        tab !== "clinicalFinding"
                          ? "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9] duration-500"
                          : "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white  duration-500"
                      }
                    >
                      Clinical Finding
                    </button>
                  </div>

                  {tab === "general" && (
                    <AdminPatientGeneral
                      patient={patient}
                      patientId={patient?._id}
                    />
                  )}

                  {tab === "clinicalFinding" && (
                    <AdminPatientClinicalFinding patientId={patient?._id} />
                  )}
                  {tab === "license" && (
                    <AdminPatientLicense patientId={patient?._id} />
                  )}
                </div>
                <div className="border-t h-6 mt-5"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdminEditDoctorPatient;
