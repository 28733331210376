import React from "react";
import Product from "./Product";

const Accessories = () => {
  return (
    <div className=" min-h-screen">
      <Product></Product>
    </div>
  );
};

export default Accessories;
