import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const DoctorAllPatientsTable = ({ patientsData }) => {
  return (
    <>
      {[...patientsData]?.reverse()?.map((patient) => (
        <tr key={patient?._id} className="shadow">
          <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
            {patient?.name}
          </td>
          <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
            {patient?.email}
          </td>

          <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
            {new Date(patient?.createdAt).toLocaleDateString()}
          </td>
          <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center">
            <Link
              to={`/doctorDashboard/PatientReport/${patient?._id}`} // Replaced 'dynamic' with the doctor's ID
              className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
            >
              <Icon icon="ic:outline-auto-graph" />
            </Link>

            <Link to={`/doctorDashboard/patient-edit-profile/${patient?._id}`}>
              {" "}
              <button className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300">
                <Icon icon="basil:edit-outline" />
              </button>
            </Link>
          </td>
        </tr>
      ))}
    </>
  );
};

export default DoctorAllPatientsTable;
