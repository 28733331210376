import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className=" min-h-screen mb-5 ">
      <div className=" mt-[60px] text-center font-bold text-4xl ">
        <h1>Privacy Policy</h1>
      </div>

      <div className="  text-justify   lg:ml-[170px] lg:mr-[170px] lg:mt-[60px] m-5  ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically. on accessing our website
          or any of our apps, you admit to the collection, storage, and use of
          your personal information you give. CareSee collects all your basic
          information like your contact number, address, and billing information
          to give you a safe, smooth, efficient, and customized experience.
          CareSee respects the privacy of the users of the Services is committed
          to reasonably protect it in all respects and does not share any of
          your personal information with the third-party. Acceptance of terms of
          Privacy Policy by using our App and our website, you intend your
          acceptance of this Privacy Policy. If you are not agreeing to this
          Privacy Policy, You would not be able to get access to or use our
          Apps. Contacting Us In case of any query about this Privacy Policy,
          use of our App or Your dealings with the App or any of the features or
          any Legal Queries about the App, please contact MPJ CareSee PVT LTD.
        </p>
      </div>

      <div className=" mt-[60px] text-center font-bold text-4xl ">
        <h1>Refund, Return & Cancellation Policy</h1>
      </div>

      <div className="  text-justify   lg:ml-[170px] lg:mr-[170px] lg:mt-[60px] m-5  ">
        <p>
          At CareSee, we respect your need for online privacy and protect all
          your personal information you share with us. Our Privacy Policy
          explains the concept and keeps on updating you with the improvement
          and expansion of our services and this privacy policy may change
          anytime, so please refer to it periodically.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
