import { Icon } from "@iconify/react";
import React from "react";
import { NavLink } from "react-router-dom";

const AdminDashboardNav = () => {
  let activeClass = {
    backgroundColor: "#291D89",
    color: "white",
  };
  return (
    <div className="bg-white px-10 py-5 rounded-md flex gap-5  shadow-md">
      <NavLink
        className="flex gap-2 items-center border border-secondary hover:bg-secondary hover:text-white py-1 md:px-4 px-2 rounded-md w-fit hover:scale-105 duration-500  shadow-lg shadow-secondary/50 text-sm md:text-base"
        style={({ isActive }) => (isActive ? activeClass : undefined)}
        to="overview"
      >
        {" "}
        <Icon icon="material-symbols:team-dashboard" /> <span>Dashboard</span>{" "}
      </NavLink>
      <NavLink
        className="flex gap-2 items-center border border-secondary hover:bg-secondary hover:text-white py-1 md:px-4 px-2 rounded-md  w-fit hover:scale-105 duration-500  shadow-lg shadow-secondary/50 text-sm md:text-base"
        style={({ isActive }) => (isActive ? activeClass : undefined)}
        to="doctors"
      >
        {" "}
        <Icon icon="fa6-solid:user-doctor" /> <span>Doctors</span>{" "}
      </NavLink>
    </div>
  );
};

export default AdminDashboardNav;
