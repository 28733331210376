import React from "react";
import UserDashboardGameCard from "./UserDashboardGameCard";

const UserDashboardIndex = () => {
  return (
    <div className="">
      <UserDashboardGameCard />
    </div>
  );
};

export default UserDashboardIndex;
