import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const BrochureCard = ({ brochure }) => {
  const [postDate, setPostDate] = useState("");
  const currentDate = moment(moment().format());

  const postDiffM = currentDate.diff(brochure?.date, "minutes");
  const postDiffH = currentDate.diff(brochure?.date, "hours");
  const postDiffD = currentDate.diff(brochure?.date, "days");

  const [isExpanded, setIsExpanded] = useState(false);
const des = isExpanded
? brochure?.brochure
: brochure?.brochure.substring(0, 400) + "..."
  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
console.log(brochure?.importantUrl
  )
  useEffect(() => {
    if (postDiffD) {
      setPostDate(`${postDiffD} days ago`);
    } else if (postDiffH) {
      setPostDate(`${postDiffH} hours ago`);
    } else {
      setPostDate(`${postDiffM} minute ago`);
    }
  }, [postDiffD, postDiffH, postDiffM]);
  return (
    <div className="">
      <h1 className=" text-2xl text-secondary font-bold">
        {brochure?.brochureTitle}{" "}
        <span className="text-sm opacity-80 font-normal">| {postDate} |</span>
      </h1>{" "}
      <p className="opacity-60 text-sm">
        {brochure?.writerName} <span className="text-secondary">|</span>{" "}
        {postDate}
      </p>
  
      <div className=" text-justify mt-3" dangerouslySetInnerHTML={{ __html: des }}></div>

      {
        brochure?.importantUrl&&<div className="flex items-center gap-2 mt-3 font-semibold">
        More information: <a className="text-primary hover:text-primary/60 hover:underline duration-300" target="_blank" rel="noreferrer" href={brochure?.importantUrl} > Click here</a>
        </div>
      }
      <button
        onClick={handleReadMore}
        className=" mt-3 bg-secondary  rounded-md text-white px-4 py-2 hover:scale-105 duration-300"
      >
        {isExpanded ? "Read less" : "Read more"}
      </button>
      <div>
        <div class="fter:h-px  my-10 flex items-center before:h-px before:flex-1  before:bg-gray-300 before:content-[''] after:h-px after:flex-1 after:bg-gray-300  after:content-['']"></div>
      </div>
    </div>
  );
};

export default BrochureCard;
